import React, { FC, useState, useEffect } from 'react';
import { SummonerSpells } from '../../../../firebase/championDAO';
import SpellImg from './SpellImg';

interface SpellsProps {
  recommendedSpells: SummonerSpells[];
}

export const loadSpellData = async (spellIds: string[]) => {
  try {
    const fetchedData: any = {};

    for (const spellId of spellIds) {
      const module = await import(
        `../../../../staticData/en_US/spells/${spellId}.ts`
      );
      fetchedData[spellId] = module.default;
    }

    return fetchedData;
  } catch (error) {
    console.error('Failed to load spell data:', error);
    return {};
  }
};

const Spells: FC<SpellsProps> = ({ recommendedSpells }) => {
  const [spellData, setSpellData] = useState<any>({});
  useEffect(() => {
    const allSpellIds = [
      ...new Set(
        recommendedSpells.flatMap((spells) => [
          spells.summonerSpell1.toString(),
          spells.summonerSpell2.toString(),
        ])
      ),
    ];
    loadSpellData(allSpellIds).then((data) => {
      setSpellData(data);
    });
  }, [recommendedSpells]);

  return (
    <div className="flex flex-col h-full p-2 rounded-md bg-primaryShade gap-1 self-start md:w-full md:max-h-[9rem]">
      {/* Header Section */}
      <div className="grid grid-cols-4 gap-y-2 items-center text-white text-1xs">
        <span className="font-semibold flex items-center justify-self-start">
          <div className="w-0.5 h-4 md:h-6 bg-green mr-1 md:mr-2"></div>
          <span className="text-sm md:text-lg font-bold">Spells</span>
        </span>
        <div className="flex text-xxs flex-col items-center lg:text-xs text-win-rate border-r border-primary pr-2 pl-1">
          <span>Win</span>
          <span>Rate</span>
        </div>
        <div className="flex text-xxs flex-col items-center lg:text-xs text-pick-rate border-r border-primary pr-2 pl-1">
          <span>Pick</span>
          <span>Rate</span>
        </div>
        <div className="flex text-xxs flex-col items-center lg:text-xs text-matches border-r border-transparent">
          <span>Matches</span>
        </div>

        {recommendedSpells.map((spells, index) => {
          return (
            <React.Fragment key={index}>
              <div className="flex flex-row gap-2 justify-start">
                <SpellImg
                  spellId={spells.summonerSpell2}
                  tooltipContent={
                    spellData[spells.summonerSpell2]?.description || ''
                  }
                  header={spellData[spells.summonerSpell2]?.name || ''}
                />
                <SpellImg
                  spellId={spells.summonerSpell1}
                  tooltipContent={
                    spellData[spells.summonerSpell1]?.description || ''
                  }
                  header={spellData[spells.summonerSpell1]?.name || ''}
                />
              </div>
              <span className="text-sm-stats md:text-2xs text-win-rate text-center">
                {Math.round(spells.winRate * 100)}%
              </span>
              <span className="text-sm-stats md:text-2xs text-pick-rate text-center">
                {Math.round(spells.pickRate * 100)}%
              </span>
              <span className="text-sm-stats md:text-2xs text-center text-matches">
                {spells.matchesPlayed}
              </span>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Spells;
