import React, { useState, useEffect, useRef } from 'react';
import CustomTooltip from '../build/CustomToolTip'; // Adjust the path accordingly
import {
  regionOptions,
  RegionOptionType,
} from '../../../lol-landing-page/components/ChampionSearchBar';

type OverlayProps = {
  imageSrc: string;
  championName: string;
  championSpecificLanePickRate: number;
  teamPositionWinRate: number;
  banRate: number;
  teamPosition: string;
  allTeamPositionsPickRates: {
    [key: string]: number;
  };
  handleTeamPositionChange: (teamPositionClicked: string) => void;
};

const Overlay: React.FC<OverlayProps> = ({
  imageSrc,
  championName,
  championSpecificLanePickRate,
  teamPositionWinRate,
  banRate,
  allTeamPositionsPickRates,
  teamPosition,
  handleTeamPositionChange,
}) => {
  const tiers = [
    'Iron',
    'Bronze',
    'Silver',
    'Gold',
    'Platinum',
    'Emerald',
    'Emerald+',
    'Diamond',
    'Master',
    'Grandmaster',
    'Challenger',
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedTier, setSelectedTier] = useState<string>('Emerald+');
  const tierDropdownRef = useRef<HTMLInputElement>(null);
  const regionDropdownRef = useRef<HTMLInputElement>(null);
  const [isRegionOpen, setIsRegionOpen] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState<RegionOptionType>(
    regionOptions[1]
  );

  const sortAllTeamPositionsPickRatesDesc = (obj: {
    [key: string]: number;
  }): { [key: string]: number } => {
    const entries = Object.entries(obj);
    entries.sort((a, b) => b[1] - a[1]);
    return Object.fromEntries(entries);
  };

  const handleRegionSelect = (region: RegionOptionType) => {
    setSelectedRegion(region);
    setIsRegionOpen(false);
  };

  const sortObjectByValuesDesc = useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tierDropdownRef.current &&
        !tierDropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
      if (
        regionDropdownRef.current &&
        !regionDropdownRef.current.contains(event.target as Node)
      ) {
        setIsRegionOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTierSelect = (tier: string) => {
    setSelectedTier(tier);
    setIsOpen(false);
  };

  allTeamPositionsPickRates = sortAllTeamPositionsPickRatesDesc(
    allTeamPositionsPickRates
  );

  return (
    <div>
      <div className="absolute top-0 left-0 pl-1 pt-3 md:p-1 flex flex-col gap-3 md:gap-2 items-start w-full">
        <div className="flex items-center justify-center md:flex-row">
          <img
            src={imageSrc}
            alt="Small Image"
            className="w-12 h-12 md:w-20 md:h-20 mr-2 rounded-full border-2 border-green"
          />
          <span className="text-center text-white text-[18px] md:text-3xl font-bold bg-secondary px-2 rounded-md bg-opacity-[0.83]">
            {championName}
          </span>
        </div>
        <div className="text-xxs rounded-md px-2 py-1 flex justify-between w-full lg:relative">
          <div className="flex gap-1 rounded-md bg-opacity-[0.8]">
            {Object.entries(allTeamPositionsPickRates).map(
              ([possibleTeamPosition, pickRate]) => {
                return (
                  <button
                    key={possibleTeamPosition}
                    onClick={() =>
                      handleTeamPositionChange(possibleTeamPosition)
                    }
                  >
                    <div
                      className={`flex flex-col px-1 py-1 rounded border-2 ${
                        possibleTeamPosition === teamPosition
                          ? 'border-green opacity-[0.9]'
                          : 'border-none opacity-[0.75]'
                      }  bg-secondary bg-opacity-[0.9]`}
                    >
                      <img
                        key={teamPosition}
                        src={`/teamposition/${possibleTeamPosition}.webp`}
                        alt=""
                        className="h-6 md:h-8 lg:h-9"
                      />
                      <p className="text-center text-pick-rate text-xs">
                        {Math.round(pickRate * 100)}%
                      </p>
                    </div>
                  </button>
                );
              }
            )}
          </div>
          <div className="flex gap-5 lg:gap-10 bg-secondary px-2 rounded-md bg-opacity-[0.83] lg:absolute lg:right-1 lg:top-[3.2rem] lg:p-2">
            <div className="flex flex-col justify-center items-center text-win-rate lg:gap-1">
              <span className="text-xs">Win Rate</span>
              <span className="text-xs">
                {Math.round(teamPositionWinRate * 100)}%
              </span>
            </div>
            <div className="flex flex-col justify-center items-center text-pick-rate lg:gap-1">
              <span className="text-xs">Pick Rate</span>
              <span className="text-xs">
                {Math.round(championSpecificLanePickRate * 100) < 1
                  ? '>1'
                  : Math.round(championSpecificLanePickRate * 100)}
                %
              </span>
            </div>
            <div className="flex flex-col justify-center items-center text-[#EBB8D4] lg:gap-1">
              <span className="text-xs">Ban Rate</span>
              <span className="text-xs">
                {Math.round(banRate * 100) < 1
                  ? '>1'
                  : Math.round(banRate * 100)}
                %
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute -top-[7%] right-0  md:-top-14 md:-right-12 p-1 md:p-14 lg:-right-11">
        <div className="mt-2 relative" ref={tierDropdownRef}>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="bg-secondary text-white px-2 py-1 md:px-3 md:py-1.5 rounded w-24 md:w-40 flex justify-between text-xs md:text-xs lg:text-sm items-center border border-secondary2"
          >
            <span className="flex justify-center items-center">
              {selectedTier}
            </span>
            <svg
              className={`transition-transform transform ${
                isOpen ? 'rotate-180' : ''
              }`}
              width="18"
              height="18"
              viewBox="0 0 24 24"
            >
              <path d="M7 10l5 5 5-5z" fill="currentColor"></path>
            </svg>
          </button>
          {isOpen && (
            <div className="absolute mt-2 w-24 h-32 md:w-40 md:h-40 shadow-lg bg-primary z-50 overflow-y-auto custom-scrollbar border border-secondary2">
              {tiers.map((tier) => (
                <button
                  disabled
                  key={tier}
                  onClick={() => handleTierSelect(tier)}
                  className="opacity-30 w-full text-left px-3 py-1.5 text-xs lg:text-sm text-white hover:bg-secondary border-b border-secondary"
                >
                  {tier}
                </button>
              ))}
            </div>
          )}
        </div>
        <div className="relative" ref={regionDropdownRef}>
          <button
            onClick={() => setIsRegionOpen(!isRegionOpen)}
            className="bg-secondary text-white px-2 py-1 md:px-3 md:py-1.5 rounded w-24 md:w-40 flex justify-between text-xs md:text-xs lg:text-sm items-center border border-secondary2 mt-2"
          >
            <span className="flex justify-center items-center">
              {selectedRegion.label}
            </span>
            <svg
              className={`transition-transform transform ${
                isRegionOpen ? 'rotate-180' : ''
              }`}
              width="18"
              height="18"
              viewBox="0 0 24 24"
            >
              <path d="M7 10l5 5 5-5z" fill="currentColor"></path>
            </svg>
          </button>
          {isRegionOpen && (
            <div className="absolute mt-2 w-24 md:w-40 h-32 md:h-40 shadow-lg bg-primary z-50 overflow-y-auto custom-scrollbar border border-secondary2">
              {regionOptions.map((region) => (
                <button
                  disabled
                  key={region.value}
                  onClick={() => handleRegionSelect(region)}
                  className="opacity-30 w-full text-left px-3 py-1.5 text-xs lg:text-sm text-white hover:bg-secondary flex items-center border-b border-secondary"
                >
                  {/* <img
                    src={region.image}
                    alt={region.label}
                    className="w-6 h-4 mr-2"
                  /> */}
                  {region.label}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Overlay;
