import React, { useEffect, useState, useRef } from 'react';
import RecommendedItems from './recommendedItems/RecommendedItems';
import {
  Boots,
  NormalItems,
  Opponent,
  Runes,
  SkillLevelUp,
  StartingItems,
  SummonerSpells,
} from '../../../firebase/championDAO';
import RecommendedRunes from './runes/RecommendedRunes';
import StartingItemsAndSpells from './startingItems/StartingItems';
import SkillOrder from './skillLevelUp/SkillOrder';
import Spells from './Spells/Spells';
import RecommendedOpponents from './counters/RecommendedOpponents';
import RecommendedBoots from './boots/RecommendedBoots';

export interface BuildSubSectionProps {
  recommendedNormalItems: NormalItems[] | null;
  recommendedRunes: Runes[];
  recommendedStartingItems: StartingItems[];
  recommendedBoots: Boots[];
  recommendedSpells: SummonerSpells[];
  recommendedSkillPath: SkillLevelUp;
  badAgainst: Opponent[];
  goodAgainst: Opponent[];
  champid: string;
}

export const loadItemData = async () => {
  try {
    const module = await import(`../../../staticData/en_US/items/items`);
    return module.default;
  } catch (error) {
    console.error('Failed to load item data:', error);
    return {};
  }
};

const BuildSubSection: React.FC<BuildSubSectionProps> = ({
  recommendedNormalItems,
  recommendedRunes,
  recommendedBoots,
  recommendedStartingItems,
  recommendedSpells,
  recommendedSkillPath,
  goodAgainst,
  badAgainst,
  champid,
}) => {
  const [itemData, setItemData] = useState<any>({});
  const [scale, setScale] = useState(1);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    loadItemData().then((data) => {
      setItemData(data);
    });
  }, [
    recommendedNormalItems,
    recommendedRunes,
    recommendedBoots,
    recommendedStartingItems,
    recommendedSpells,
    recommendedSkillPath,
    goodAgainst,
    badAgainst,
    champid,
  ]);

  useEffect(() => {
    const checkOverflow = () => {
      const container = containerRef.current;
      if (container) {
        if (container.scrollWidth > container.clientWidth) {
          setScale(0.75); // Adjust scale value as needed
        } else {
          setScale(1);
        }
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  return (
    <div className="fade-in-sm w-full flex flex-col justify-center items-center">
      {/*mobile  */}
      <div className="flex flex-col md:hidden gap-2 justify-center items-center w-[99%] max-w-[30rem]">
        <div className="flex flex-row items-start gap-2 w-97/100">
          <div className="flex flex-col gap-2 min-w-[30%]">
            <div className="rounded-md bg-primaryShade md:p-2 md:w-full pr-2 pl-2">
              <span className="pt-2 pb-2 font-semibold flex items-center mt-1">
                <div className="w-0.5 h-4 md:h-6 bg-green mr-1 md:mr-2 "></div>
                <span className="text-sm md:text-lg font-beaufort">Runes</span>
              </span>

              <RecommendedRunes
                champId={champid}
                recommendedRunes={recommendedRunes}
              />
            </div>
            <SkillOrder order={recommendedSkillPath} champid={champid} />
          </div>
          <div className="flex flex-col gap-4 w-full">
            <div className="flex-shrink-0 flex-grow-0 w-auto">
              <Spells recommendedSpells={recommendedSpells} />
            </div>
            <StartingItemsAndSpells
              recommendedStartingItems={recommendedStartingItems}
              itemData={itemData}
            />
            <RecommendedBoots
              recommendedBoots={recommendedBoots}
              itemData={itemData}
            />
          </div>
        </div>
        <RecommendedItems
          recommendedNormalItems={recommendedNormalItems}
          itemData={itemData}
        />
        <RecommendedOpponents
          opponents={goodAgainst}
          title={`Weak vs ${champid}`}
        />
        <RecommendedOpponents
          opponents={badAgainst}
          title={`Strong vs. ${champid}`}
        />
      </div>

      {/* tablet */}
      
      <div className="hidden md:flex lg:hidden justify-center mx-auto w-[97%] gap-2">
        <div className="max-w-[30rem] flex flex-col gap-y-2">
          <div className="rounded-md bg-primaryShade md:p-2 pr-2 pl-2 self-start w-full">
            <span className="pt-2 pb-2 font-semibold flex items-center mt-1">
              <div className="w-0.5 h-4 md:h-6 bg-green mr-1 md:mr-2 "></div>
              <span className="text-sm md:text-lg font-beaufort">Runes</span>
            </span>
            <RecommendedRunes
              champId={champid}
              recommendedRunes={recommendedRunes}
            />
          </div>
          <SkillOrder order={recommendedSkillPath} champid={champid} />

          <Spells recommendedSpells={recommendedSpells} />
          <StartingItemsAndSpells
            recommendedStartingItems={recommendedStartingItems}
            itemData={itemData}
          />
          <RecommendedBoots
            recommendedBoots={recommendedBoots}
            itemData={itemData}
          />
        </div>
        <div className="self-start flex flex-col gap-2">
          <RecommendedItems
            recommendedNormalItems={recommendedNormalItems}
            itemData={itemData}
          />
          <RecommendedOpponents
            opponents={goodAgainst}
            title={`Weak vs ${champid}`}
          />
          <RecommendedOpponents
            opponents={badAgainst}
            title={`Strong vs. ${champid}`}
          />
        </div>
      </div>

      {/* Desktop */}
      <div
        ref={containerRef}
        className="hidden lg:flex lg:flex-row gap-x-3 gap-y-4 justify-center w-full max-w-[84.1rem] "
        style={{ transform: `scale(${scale})`, transformOrigin: 'top center' }}
      >
        <div className="flex flex-col w-full md:w-auto">
          <div className="rounded-md bg-primaryShade p-2 md:w-full w-28 mb-2">
            <span className="col-span-5 font-semibold flex items-center">
              <div className="w-0.5 h-4 md:h-6 bg-green mr-1 md:mr-2"></div>
              <span className="text-sm md:text-lg font-bold">Runes</span>
            </span>
            <RecommendedRunes
              champId={champid}
              recommendedRunes={recommendedRunes}
            />
          </div>
          <SkillOrder order={recommendedSkillPath} champid={champid} />
        </div>
        <div className="flex flex-col gap-2 align-middle w-64 md:w-full">
          <RecommendedItems
            recommendedNormalItems={recommendedNormalItems}
            itemData={itemData}
          />
          <div className="rounded-md bg-primaryShade w-full md:w-96">
            <RecommendedOpponents
              opponents={goodAgainst}
              title={`Weak vs. ${champid}`}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2 lg:w-full w-60">
          <Spells recommendedSpells={recommendedSpells} />
          <StartingItemsAndSpells
            recommendedStartingItems={recommendedStartingItems}
            itemData={itemData}
          />
          <RecommendedBoots
            recommendedBoots={recommendedBoots}
            itemData={itemData}
          />
          <RecommendedOpponents
            opponents={badAgainst}
            title={`Strong vs. ${champid}`}
          />
        </div>
      </div>
    </div>
    
  );
};

export default BuildSubSection;
