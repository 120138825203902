import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  collection,
  limit,
  query,
  where,
  orderBy,
  getDocs,
  getDoc,
  doc,
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBVyY0bfc40ahs_zAj1VVNmWc6NxGDHuFU',
  authDomain: 'league-bm.firebaseapp.com',
  projectId: 'league-bm',
  storageBucket: 'league-bm.appspot.com',
  messagingSenderId: '422108285165',
  appId: '1:422108285165:web:9bb7e680ed9b4aad78a4e4',
  measurementId: 'G-47NN3MBXC7',
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export interface StartingItems {
  pickRate: number;
  matchesPlayed: number;
  startingItems: number[]; // Maybe rename to items or itemIds only? confusing
  winRate: number;
}

export interface NormalItems {
  matchesPlayed: number;
  winRate: number;
  pickRate: number;
  item1: number;
  item2: number;
  item3: number;
}

export interface Runes {
  primaryStyle: number;
  primaryStylePerk1: number;
  primaryStylePerk2: number;
  primaryStylePerk3: number;
  primaryStylePerk4: number;
  subStyle: number;
  subStylePerk1: number;
  subStylePerk2: number;
  statMod1: number;
  statMod2: number;
  statMod3: number;
  matchesPlayed: number;
  styleWinRate: number;
  stylePickRate: number;
}

export interface Boots {
  winRate: number;
  matchesPlayed: number;
  boots: number;
  pickRate: number;
}

export interface Opponent {
  earlyGoldEarned: number;
  earlyMinionsKilled: number;
  earlySoloKillsAgainstLaneOpponent: number;
  kda: number;
  matchesPlayed: number;
  opponentChampionName: string;
  opponentEarlyGoldEarned: number;
  opponentEarlyMinionsKilled: number;
  opponentEarlySoloKillsAgainstLaneOpponent: number;
  opponentKda: number;
  winRate: number;
}

export interface SkillLevelUp {
  pickRate: number;
  winRate: number;
  skillLevelUpOrder: string;
  matchesPlayed: number;
}

export interface SummonerSpells {
  matchesPlayed: number;
  summonerSpell1: number;
  winRate: number;
  pickRate: number;
  summonerSpell2: number;
}

export interface ChampionStats {
  documentId: string;
  championName: string;
  teamPosition: string;
  teamPositionPickRate: number;
  teamPositionWinRate: number;
  recommendedSkillLevelUp: SkillLevelUp;
  recommendedStartingItems: StartingItems[];
  recommendedNormalItems: NormalItems[];
  recommendedRunes: Runes[];
  recommendedBoots: Boots[];
  goodAgainst: Opponent[];
  badAgainst: Opponent[];
  recommendedSummonerSpells: SummonerSpells[];
  allTeamPositionsPickRates: {
    [key: string]: number;
  };
  banRate: number;
  championSpecificLanePickRate: number;
}

const COLLECTION_ID = 'new_build_recommendations_test_7';

export async function getBuildDataByDocumentId(
  documentId: string
): Promise<ChampionStats | null> {
  const championsCollection = collection(db, COLLECTION_ID);

  try {
    const docRef = doc(championsCollection, documentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const championStats: ChampionStats = {
        ...(data as ChampionStats),
        documentId: docSnap.id,
      };
      return championStats;
    } else {
      console.log('No such champion!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching champion:', error);
    return null;
  }
}

export async function getChampion(
  championName: string
): Promise<ChampionStats | null> {
  const championsCollection = collection(db, COLLECTION_ID);

  const q = query(
    championsCollection,
    where('championName', '==', championName),
    orderBy('teamPositionPickRate', 'desc'),
    limit(1)
  );

  try {
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      const data = doc.data();
      const championStats: ChampionStats = {
        ...(data as ChampionStats),
        documentId: doc.id,
      };
      return championStats;
    } else {
      console.log('No such champion!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching champion:', error);
    return null;
  }
}

export default getChampion;
