import { type } from 'os';
import React, { useState } from 'react';
import ChampionOverlay from './ChampionName';

type ChampionCircleBarProps = {
  championSpecificLanePickRate: number;
  teamPositionWinRate: number;
  banRate: number;
};

type ChampionImageProps = {
  championId: string;
  championsmallimageSrc: string;
  championSpecificLanePickRate: number;
  teamPositionWinRate: number;
  banRate: number;
  teamPosition: string;
  allTeamPositionsPickRates: {
    [key: string]: number;
  };
  handleTeamPositionChange: (teamPositionClicked: string) => void;
};
const ChampionImageSkeleton: React.FC = () => (
  <div className="w-full h-full">
    <div
      role="status"
      className="flex items-center justify-center w-full h-full absolute top-0 left-0 bg-gradient-to-r from-slate-950 via-slate-800 to-slate-700 rounded-sm animate-pulse"
    >
      <svg
        className="w-10 h-10 text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 16 20"
      >
        <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
        <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM9 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2Zm4 .382a1 1 0 0 1-1.447.894L10 13v-2l1.553-1.276a1 1 0 0 1 1.447.894v2.764Z" />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

const ChampionImage: React.FC<ChampionImageProps> = ({
  championId,
  championsmallimageSrc,
  championSpecificLanePickRate,
  teamPositionWinRate,
  banRate,
  allTeamPositionsPickRates,
  teamPosition,
  handleTeamPositionChange,
}) => {
  const imageUrl = `${process.env.PUBLIC_URL}/championBackground/${championId}_0.jpg`;

  // State to track if the image is loaded
  const [isImageLoaded, setImageLoaded] = useState(false);

  return (
    <div>
      <div className="w-full h-[8.5rem] md:h-[13rem] relative bg-primary">
        {/* Always render the image, but start with opacity 0 */}
        <img
          src={imageUrl}
          alt={championId}
          className={` rounded w-full h-full object-cover object-center-minus-20 md:object-center-minus-120 transition-opacity duration-[1200ms] ${
            isImageLoaded ? 'opacity-100' : 'opacity-0'
          }`}
          onLoad={() => setImageLoaded(true)}
          data-loaded={isImageLoaded}
        />

        {/* Display the skeleton loader if the image isn't loaded */}
        {!isImageLoaded && <ChampionImageSkeleton />}

        {/* Render the overlay once the image is loaded */}
        {isImageLoaded && (
          <ChampionOverlay
            handleTeamPositionChange={handleTeamPositionChange}
            teamPosition={teamPosition}
            championSpecificLanePickRate={championSpecificLanePickRate}
            teamPositionWinRate={teamPositionWinRate}
            banRate={banRate}
            imageSrc={championsmallimageSrc}
            championName={championId}
            allTeamPositionsPickRates={allTeamPositionsPickRates}
          />
        )}
      </div>
    </div>
  );
};

export default ChampionImage;
