import React from 'react';

const RankDetails: React.FC = () => {
  const ranks = [
    {
      mode: 'Ranked Solo/Duo',
      rank: 'Grand Master 4',
      lp: '70LP',
      wins: 80,
      losses: 74,
      img: '/tiers/platinum.png',
    },
    {
      mode: 'Flex',
      rank: 'Iron 4',
      lp: '70LP',
      wins: 80,
      losses: 74,
      img: '/tiers/gold.png',
    },
    {
      mode: 'Arena',
      rank: 'Silver 1',
      lp: '50LP',
      wins: 120,
      losses: 110,
      img: '/tiers/gold.png',
    },
  ];

  return (
    <div className="flex w-full justify-between space-x-1">
      {ranks.map((rank) => (
        <div
          key={rank.mode}
          className="bg-secondary p-2 rounded flex flex-col items-center w-1/3 shadow-lg shadow-slate-700 relative"
        >
          {' '}
          {/* Ensure 'relative' is here */}
          <img
            src={rank.img}
            alt={`${rank.rank} Badge`}
            className="w-8 h-8 mb-2"
          />
          <div className="text-xs font-bold">{rank.mode}</div>
          <div className="text-xs">{rank.rank}</div>
          <div className="text-xs opacity-70">{rank.lp}</div>
          <div className="text-xs opacity-40">{`${rank.wins}W ${
            rank.losses
          }L (${Math.floor(
            (rank.wins / (rank.wins + rank.losses)) * 100
          )}%)`}</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4 absolute top-2 right-2 opacity-50"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      ))}
    </div>
  );
};

export default RankDetails;
