const champions = [
  {
    champId: 'Aatrox',
    name: 'Aatrox',
    quote: 'the Darkin Blade',
    image: '/champimages/Aatrox.png',
  },
  {
    champId: 'Ahri',
    name: 'Ahri',
    quote: 'the Nine-Tailed Fox',
    image: '/champimages/Ahri.png',
  },
  {
    champId: 'Akali',
    name: 'Akali',
    quote: 'the Rogue Assassin',
    image: '/champimages/Akali.png',
  },
  {
    champId: 'Akshan',
    name: 'Akshan',
    quote: 'the Rogue Sentinel',
    image: '/champimages/Akshan.png',
  },
  {
    champId: 'Alistar',
    name: 'Alistar',
    quote: 'the Minotaur',
    image: '/champimages/Alistar.png',
  },
  {
    champId: 'Amumu',
    name: 'Amumu',
    quote: 'the Sad Mummy',
    image: '/champimages/Amumu.png',
  },
  {
    champId: 'Anivia',
    name: 'Anivia',
    quote: 'the Cryophoenix',
    image: '/champimages/Anivia.png',
  },
  {
    champId: 'Annie',
    name: 'Annie',
    quote: 'the Dark Child',
    image: '/champimages/Annie.png',
  },
  {
    champId: 'Aphelios',
    name: 'Aphelios',
    quote: 'the Weapon of the Faithful',
    image: '/champimages/Aphelios.png',
  },
  {
    champId: 'Ashe',
    name: 'Ashe',
    quote: 'the Frost Archer',
    image: '/champimages/Ashe.png',
  },
  {
    champId: 'AurelionSol',
    name: 'Aurelion Sol',
    quote: 'The Star Forger',
    image: '/champimages/AurelionSol.png',
  },
  {
    champId: 'Azir',
    name: 'Azir',
    quote: 'the Emperor of the Sands',
    image: '/champimages/Azir.png',
  },
  {
    champId: 'Bard',
    name: 'Bard',
    quote: 'the Wandering Caretaker',
    image: '/champimages/Bard.png',
  },
  {
    champId: 'Belveth',
    name: "Bel'Veth",
    quote: 'the Empress of the Void',
    image: '/champimages/Belveth.png',
  },
  {
    champId: 'Blitzcrank',
    name: 'Blitzcrank',
    quote: 'the Great Steam Golem',
    image: '/champimages/Blitzcrank.png',
  },
  {
    champId: 'Brand',
    name: 'Brand',
    quote: 'the Burning Vengeance',
    image: '/champimages/Brand.png',
  },
  {
    champId: 'Braum',
    name: 'Braum',
    quote: 'the Heart of the Freljord',
    image: '/champimages/Braum.png',
  },
  {
    champId: 'Briar',
    name: 'Briar',
    quote: 'the Restrained Hunger',
    image: '/champimages/Briar.png',
  },
  {
    champId: 'Caitlyn',
    name: 'Caitlyn',
    quote: 'the Sheriff of Piltover',
    image: '/champimages/Caitlyn.png',
  },
  {
    champId: 'Camille',
    name: 'Camille',
    quote: 'the Steel Shadow',
    image: '/champimages/Camille.png',
  },
  {
    champId: 'Cassiopeia',
    name: 'Cassiopeia',
    quote: "the Serpent's Embrace",
    image: '/champimages/Cassiopeia.png',
  },
  {
    champId: 'Chogath',
    name: "Cho'Gath",
    quote: 'the Terror of the Void',
    image: '/champimages/Chogath.png',
  },
  {
    champId: 'Corki',
    name: 'Corki',
    quote: 'the Daring Bombardier',
    image: '/champimages/Corki.png',
  },
  {
    champId: 'Darius',
    name: 'Darius',
    quote: 'the Hand of Noxus',
    image: '/champimages/Darius.png',
  },
  {
    champId: 'Diana',
    name: 'Diana',
    quote: 'Scorn of the Moon',
    image: '/champimages/Diana.png',
  },
  {
    champId: 'Draven',
    name: 'Draven',
    quote: 'the Glorious Executioner',
    image: '/champimages/Draven.png',
  },
  {
    champId: 'DrMundo',
    name: 'Dr. Mundo',
    quote: 'the Madman of Zaun',
    image: '/champimages/DrMundo.png',
  },
  {
    champId: 'Ekko',
    name: 'Ekko',
    quote: 'the Boy Who Shattered Time',
    image: '/champimages/Ekko.png',
  },
  {
    champId: 'Elise',
    name: 'Elise',
    quote: 'the Spider Queen',
    image: '/champimages/Elise.png',
  },
  {
    champId: 'Evelynn',
    name: 'Evelynn',
    quote: "Agony's Embrace",
    image: '/champimages/Evelynn.png',
  },
  {
    champId: 'Ezreal',
    name: 'Ezreal',
    quote: 'the Prodigal Explorer',
    image: '/champimages/Ezreal.png',
  },
  {
    champId: 'Fiddlesticks',
    name: 'Fiddlesticks',
    quote: 'the Ancient Fear',
    image: '/champimages/Fiddlesticks.png',
  },
  {
    champId: 'Fiora',
    name: 'Fiora',
    quote: 'the Grand Duelist',
    image: '/champimages/Fiora.png',
  },
  {
    champId: 'Fizz',
    name: 'Fizz',
    quote: 'the Tidal Trickster',
    image: '/champimages/Fizz.png',
  },
  {
    champId: 'Galio',
    name: 'Galio',
    quote: 'the Colossus',
    image: '/champimages/Galio.png',
  },
  {
    champId: 'Gangplank',
    name: 'Gangplank',
    quote: 'the Saltwater Scourge',
    image: '/champimages/Gangplank.png',
  },
  {
    champId: 'Garen',
    name: 'Garen',
    quote: 'The Might of Demacia',
    image: '/champimages/Garen.png',
  },
  {
    champId: 'Gnar',
    name: 'Gnar',
    quote: 'the Missing Link',
    image: '/champimages/Gnar.png',
  },
  {
    champId: 'Gragas',
    name: 'Gragas',
    quote: 'the Rabble Rouser',
    image: '/champimages/Gragas.png',
  },
  {
    champId: 'Graves',
    name: 'Graves',
    quote: 'the Outlaw',
    image: '/champimages/Graves.png',
  },
  {
    champId: 'Gwen',
    name: 'Gwen',
    quote: 'The Hallowed Seamstress',
    image: '/champimages/Gwen.png',
  },
  {
    champId: 'Hecarim',
    name: 'Hecarim',
    quote: 'the Shadow of War',
    image: '/champimages/Hecarim.png',
  },
  {
    champId: 'Heimerdinger',
    name: 'Heimerdinger',
    quote: 'the Revered Inventor',
    image: '/champimages/Heimerdinger.png',
  },
  {
    champId: 'Hwei',
    name: 'Hwei',
    quote: 'the Visionary',
    image: '/champimages/Hwei.png',
  },
  {
    champId: 'Illaoi',
    name: 'Illaoi',
    quote: 'the Kraken Priestess',
    image: '/champimages/Illaoi.png',
  },
  {
    champId: 'Irelia',
    name: 'Irelia',
    quote: 'the Blade Dancer',
    image: '/champimages/Irelia.png',
  },
  {
    champId: 'Ivern',
    name: 'Ivern',
    quote: 'the Green Father',
    image: '/champimages/Ivern.png',
  },
  {
    champId: 'Janna',
    name: 'Janna',
    quote: "the Storm's Fury",
    image: '/champimages/Janna.png',
  },
  {
    champId: 'JarvanIV',
    name: 'Jarvan IV',
    quote: 'the Exemplar of Demacia',
    image: '/champimages/JarvanIV.png',
  },
  {
    champId: 'Jax',
    name: 'Jax',
    quote: 'Grandmaster at Arms',
    image: '/champimages/Jax.png',
  },
  {
    champId: 'Jayce',
    name: 'Jayce',
    quote: 'the Defender of Tomorrow',
    image: '/champimages/Jayce.png',
  },
  {
    champId: 'Jhin',
    name: 'Jhin',
    quote: 'the Virtuoso',
    image: '/champimages/Jhin.png',
  },
  {
    champId: 'Jinx',
    name: 'Jinx',
    quote: 'the Loose Cannon',
    image: '/champimages/Jinx.png',
  },
  {
    champId: 'Kaisa',
    name: "Kai'Sa",
    quote: 'Daughter of the Void',
    image: '/champimages/Kaisa.png',
  },
  {
    champId: 'Kalista',
    name: 'Kalista',
    quote: 'the Spear of Vengeance',
    image: '/champimages/Kalista.png',
  },
  {
    champId: 'Karma',
    name: 'Karma',
    quote: 'the Enlightened One',
    image: '/champimages/Karma.png',
  },
  {
    champId: 'Karthus',
    name: 'Karthus',
    quote: 'the Deathsinger',
    image: '/champimages/Karthus.png',
  },
  {
    champId: 'Kassadin',
    name: 'Kassadin',
    quote: 'the Void Walker',
    image: '/champimages/Kassadin.png',
  },
  {
    champId: 'Katarina',
    name: 'Katarina',
    quote: 'the Sinister Blade',
    image: '/champimages/Katarina.png',
  },
  {
    champId: 'Kayle',
    name: 'Kayle',
    quote: 'the Righteous',
    image: '/champimages/Kayle.png',
  },
  {
    champId: 'Kayn',
    name: 'Kayn',
    quote: 'the Shadow Reaper',
    image: '/champimages/Kayn.png',
  },
  {
    champId: 'Kennen',
    name: 'Kennen',
    quote: 'the Heart of the Tempest',
    image: '/champimages/Kennen.png',
  },
  {
    champId: 'Khazix',
    name: "Kha'Zix",
    quote: 'the Voidreaver',
    image: '/champimages/Khazix.png',
  },
  {
    champId: 'Kindred',
    name: 'Kindred',
    quote: 'The Eternal Hunters',
    image: '/champimages/Kindred.png',
  },
  {
    champId: 'Kled',
    name: 'Kled',
    quote: 'the Cantankerous Cavalier',
    image: '/champimages/Kled.png',
  },
  {
    champId: 'KogMaw',
    name: "Kog'Maw",
    quote: 'the Mouth of the Abyss',
    image: '/champimages/KogMaw.png',
  },
  {
    champId: 'KSante',
    name: "K'Sante",
    quote: 'the Pride of Nazumah',
    image: '/champimages/KSante.png',
  },
  {
    champId: 'Leblanc',
    name: 'LeBlanc',
    quote: 'the Deceiver',
    image: '/champimages/Leblanc.png',
  },
  {
    champId: 'LeeSin',
    name: 'Lee Sin',
    quote: 'the Blind Monk',
    image: '/champimages/LeeSin.png',
  },
  {
    champId: 'Leona',
    name: 'Leona',
    quote: 'the Radiant Dawn',
    image: '/champimages/Leona.png',
  },
  {
    champId: 'Lillia',
    name: 'Lillia',
    quote: 'the Bashful Bloom',
    image: '/champimages/Lillia.png',
  },
  {
    champId: 'Lissandra',
    name: 'Lissandra',
    quote: 'the Ice Witch',
    image: '/champimages/Lissandra.png',
  },
  {
    champId: 'Lucian',
    name: 'Lucian',
    quote: 'the Purifier',
    image: '/champimages/Lucian.png',
  },
  {
    champId: 'Lulu',
    name: 'Lulu',
    quote: 'the Fae Sorceress',
    image: '/champimages/Lulu.png',
  },
  {
    champId: 'Lux',
    name: 'Lux',
    quote: 'the Lady of Luminosity',
    image: '/champimages/Lux.png',
  },
  {
    champId: 'Malphite',
    name: 'Malphite',
    quote: 'Shard of the Monolith',
    image: '/champimages/Malphite.png',
  },
  {
    champId: 'Malzahar',
    name: 'Malzahar',
    quote: 'the Prophet of the Void',
    image: '/champimages/Malzahar.png',
  },
  {
    champId: 'Maokai',
    name: 'Maokai',
    quote: 'the Twisted Treant',
    image: '/champimages/Maokai.png',
  },
  {
    champId: 'MasterYi',
    name: 'Master Yi',
    quote: 'the Wuju Bladesman',
    image: '/champimages/MasterYi.png',
  },
  {
    champId: 'Milio',
    name: 'Milio',
    quote: 'The Gentle Flame',
    image: '/champimages/Milio.png',
  },
  {
    champId: 'MissFortune',
    name: 'Miss Fortune',
    quote: 'the Bounty Hunter',
    image: '/champimages/MissFortune.png',
  },
  {
    champId: 'MonkeyKing',
    name: 'Wukong',
    quote: 'the Monkey King',
    image: '/champimages/MonkeyKing.png',
  },
  {
    champId: 'Mordekaiser',
    name: 'Mordekaiser',
    quote: 'the Iron Revenant',
    image: '/champimages/Mordekaiser.png',
  },
  {
    champId: 'Morgana',
    name: 'Morgana',
    quote: 'the Fallen',
    image: '/champimages/Morgana.png',
  },
  {
    champId: 'Naafiri',
    name: 'Naafiri',
    quote: 'the Hound of a Hundred Bites',
    image: '/champimages/Naafiri.png',
  },
  {
    champId: 'Nami',
    name: 'Nami',
    quote: 'the Tidecaller',
    image: '/champimages/Nami.png',
  },
  {
    champId: 'Nasus',
    name: 'Nasus',
    quote: 'the Curator of the Sands',
    image: '/champimages/Nasus.png',
  },
  {
    champId: 'Nautilus',
    name: 'Nautilus',
    quote: 'the Titan of the Depths',
    image: '/champimages/Nautilus.png',
  },
  {
    champId: 'Neeko',
    name: 'Neeko',
    quote: 'the Curious Chameleon',
    image: '/champimages/Neeko.png',
  },
  {
    champId: 'Nidalee',
    name: 'Nidalee',
    quote: 'the Bestial Huntress',
    image: '/champimages/Nidalee.png',
  },
  {
    champId: 'Nilah',
    name: 'Nilah',
    quote: 'the Joy Unbound',
    image: '/champimages/Nilah.png',
  },
  {
    champId: 'Nocturne',
    name: 'Nocturne',
    quote: 'the Eternal Nightmare',
    image: '/champimages/Nocturne.png',
  },
  {
    champId: 'Nunu',
    name: 'Nunu & Willump',
    quote: 'the Boy and His Yeti',
    image: '/champimages/Nunu.png',
  },
  {
    champId: 'Olaf',
    name: 'Olaf',
    quote: 'the Berserker',
    image: '/champimages/Olaf.png',
  },
  {
    champId: 'Orianna',
    name: 'Orianna',
    quote: 'the Lady of Clockwork',
    image: '/champimages/Orianna.png',
  },
  {
    champId: 'Ornn',
    name: 'Ornn',
    quote: 'The Fire below the Mountain',
    image: '/champimages/Ornn.png',
  },
  {
    champId: 'Pantheon',
    name: 'Pantheon',
    quote: 'the Unbreakable Spear',
    image: '/champimages/Pantheon.png',
  },
  {
    champId: 'Poppy',
    name: 'Poppy',
    quote: 'Keeper of the Hammer',
    image: '/champimages/Poppy.png',
  },
  {
    champId: 'Pyke',
    name: 'Pyke',
    quote: 'the Bloodharbor Ripper',
    image: '/champimages/Pyke.png',
  },
  {
    champId: 'Qiyana',
    name: 'Qiyana',
    quote: 'Empress of the Elements',
    image: '/champimages/Qiyana.png',
  },
  {
    champId: 'Quinn',
    name: 'Quinn',
    quote: "Demacia's Wings",
    image: '/champimages/Quinn.png',
  },
  {
    champId: 'Rakan',
    name: 'Rakan',
    quote: 'The Charmer',
    image: '/champimages/Rakan.png',
  },
  {
    champId: 'Rammus',
    name: 'Rammus',
    quote: 'the Armordillo',
    image: '/champimages/Rammus.png',
  },
  {
    champId: 'RekSai',
    name: "Rek'Sai",
    quote: 'the Void Burrower',
    image: '/champimages/RekSai.png',
  },
  {
    champId: 'Rell',
    name: 'Rell',
    quote: 'the Iron Maiden',
    image: '/champimages/Rell.png',
  },
  {
    champId: 'Renata',
    name: 'Renata Glasc',
    quote: 'the Chem-Baroness',
    image: '/champimages/Renata.png',
  },
  {
    champId: 'Renekton',
    name: 'Renekton',
    quote: 'the Butcher of the Sands',
    image: '/champimages/Renekton.png',
  },
  {
    champId: 'Rengar',
    name: 'Rengar',
    quote: 'the Pridestalker',
    image: '/champimages/Rengar.png',
  },
  {
    champId: 'Riven',
    name: 'Riven',
    quote: 'the Exile',
    image: '/champimages/Riven.png',
  },
  {
    champId: 'Rumble',
    name: 'Rumble',
    quote: 'the Mechanized Menace',
    image: '/champimages/Rumble.png',
  },
  {
    champId: 'Ryze',
    name: 'Ryze',
    quote: 'the Rune Mage',
    image: '/champimages/Ryze.png',
  },
  {
    champId: 'Samira',
    name: 'Samira',
    quote: 'the Desert Rose',
    image: '/champimages/Samira.png',
  },
  {
    champId: 'Sejuani',
    name: 'Sejuani',
    quote: 'Fury of the North',
    image: '/champimages/Sejuani.png',
  },
  {
    champId: 'Senna',
    name: 'Senna',
    quote: 'the Redeemer',
    image: '/champimages/Senna.png',
  },
  {
    champId: 'Seraphine',
    name: 'Seraphine',
    quote: 'the Starry-Eyed Songstress',
    image: '/champimages/Seraphine.png',
  },
  {
    champId: 'Sett',
    name: 'Sett',
    quote: 'the Boss',
    image: '/champimages/Sett.png',
  },
  {
    champId: 'Shaco',
    name: 'Shaco',
    quote: 'the Demon Jester',
    image: '/champimages/Shaco.png',
  },
  {
    champId: 'Shen',
    name: 'Shen',
    quote: 'the Eye of Twilight',
    image: '/champimages/Shen.png',
  },
  {
    champId: 'Shyvana',
    name: 'Shyvana',
    quote: 'the Half-Dragon',
    image: '/champimages/Shyvana.png',
  },
  {
    champId: 'Singed',
    name: 'Singed',
    quote: 'the Mad Chemist',
    image: '/champimages/Singed.png',
  },
  {
    champId: 'Sion',
    name: 'Sion',
    quote: 'The Undead Juggernaut',
    image: '/champimages/Sion.png',
  },
  {
    champId: 'Sivir',
    name: 'Sivir',
    quote: 'the Battle Mistress',
    image: '/champimages/Sivir.png',
  },
  {
    champId: 'Skarner',
    name: 'Skarner',
    quote: 'the Crystal Vanguard',
    image: '/champimages/Skarner.png',
  },
  {
    champId: 'Sona',
    name: 'Sona',
    quote: 'Maven of the Strings',
    image: '/champimages/Sona.png',
  },
  {
    champId: 'Soraka',
    name: 'Soraka',
    quote: 'the Starchild',
    image: '/champimages/Soraka.png',
  },
  {
    champId: 'Swain',
    name: 'Swain',
    quote: 'the Noxian Grand General',
    image: '/champimages/Swain.png',
  },
  {
    champId: 'Sylas',
    name: 'Sylas',
    quote: 'the Unshackled',
    image: '/champimages/Sylas.png',
  },
  {
    champId: 'Syndra',
    name: 'Syndra',
    quote: 'the Dark Sovereign',
    image: '/champimages/Syndra.png',
  },
  {
    champId: 'TahmKench',
    name: 'Tahm Kench',
    quote: 'The River King',
    image: '/champimages/TahmKench.png',
  },
  {
    champId: 'Taliyah',
    name: 'Taliyah',
    quote: 'the Stoneweaver',
    image: '/champimages/Taliyah.png',
  },
  {
    champId: 'Talon',
    name: 'Talon',
    quote: "the Blade's Shadow",
    image: '/champimages/Talon.png',
  },
  {
    champId: 'Taric',
    name: 'Taric',
    quote: 'the Shield of Valoran',
    image: '/champimages/Taric.png',
  },
  {
    champId: 'Teemo',
    name: 'Teemo',
    quote: 'the Swift Scout',
    image: '/champimages/Teemo.png',
  },
  {
    champId: 'Thresh',
    name: 'Thresh',
    quote: 'the Chain Warden',
    image: '/champimages/Thresh.png',
  },
  {
    champId: 'Tristana',
    name: 'Tristana',
    quote: 'the Yordle Gunner',
    image: '/champimages/Tristana.png',
  },
  {
    champId: 'Trundle',
    name: 'Trundle',
    quote: 'the Troll King',
    image: '/champimages/Trundle.png',
  },
  {
    champId: 'Tryndamere',
    name: 'Tryndamere',
    quote: 'the Barbarian King',
    image: '/champimages/Tryndamere.png',
  },
  {
    champId: 'TwistedFate',
    name: 'Twisted Fate',
    quote: 'the Card Master',
    image: '/champimages/TwistedFate.png',
  },
  {
    champId: 'Twitch',
    name: 'Twitch',
    quote: 'the Plague Rat',
    image: '/champimages/Twitch.png',
  },
  {
    champId: 'Udyr',
    name: 'Udyr',
    quote: 'the Spirit Walker',
    image: '/champimages/Udyr.png',
  },
  {
    champId: 'Urgot',
    name: 'Urgot',
    quote: 'the Dreadnought',
    image: '/champimages/Urgot.png',
  },
  {
    champId: 'Varus',
    name: 'Varus',
    quote: 'the Arrow of Retribution',
    image: '/champimages/Varus.png',
  },
  {
    champId: 'Vayne',
    name: 'Vayne',
    quote: 'the Night Hunter',
    image: '/champimages/Vayne.png',
  },
  {
    champId: 'Veigar',
    name: 'Veigar',
    quote: 'the Tiny Master of Evil',
    image: '/champimages/Veigar.png',
  },
  {
    champId: 'Velkoz',
    name: "Vel'Koz",
    quote: 'the Eye of the Void',
    image: '/champimages/Velkoz.png',
  },
  {
    champId: 'Vex',
    name: 'Vex',
    quote: 'the Gloomist',
    image: '/champimages/Vex.png',
  },
  {
    champId: 'Vi',
    name: 'Vi',
    quote: 'the Piltover Enforcer',
    image: '/champimages/Vi.png',
  },
  {
    champId: 'Viego',
    name: 'Viego',
    quote: 'The Ruined King',
    image: '/champimages/Viego.png',
  },
  {
    champId: 'Viktor',
    name: 'Viktor',
    quote: 'the Machine Herald',
    image: '/champimages/Viktor.png',
  },
  {
    champId: 'Vladimir',
    name: 'Vladimir',
    quote: 'the Crimson Reaper',
    image: '/champimages/Vladimir.png',
  },
  {
    champId: 'Volibear',
    name: 'Volibear',
    quote: 'the Relentless Storm',
    image: '/champimages/Volibear.png',
  },
  {
    champId: 'Warwick',
    name: 'Warwick',
    quote: 'the Uncaged Wrath of Zaun',
    image: '/champimages/Warwick.png',
  },
  {
    champId: 'Xayah',
    name: 'Xayah',
    quote: 'the Rebel',
    image: '/champimages/Xayah.png',
  },
  {
    champId: 'Xerath',
    name: 'Xerath',
    quote: 'the Magus Ascendant',
    image: '/champimages/Xerath.png',
  },
  {
    champId: 'XinZhao',
    name: 'Xin Zhao',
    quote: 'the Seneschal of Demacia',
    image: '/champimages/XinZhao.png',
  },
  {
    champId: 'Yasuo',
    name: 'Yasuo',
    quote: 'the Unforgiven',
    image: '/champimages/Yasuo.png',
  },
  {
    champId: 'Yone',
    name: 'Yone',
    quote: 'the Unforgotten',
    image: '/champimages/Yone.png',
  },
  {
    champId: 'Yorick',
    name: 'Yorick',
    quote: 'Shepherd of Souls',
    image: '/champimages/Yorick.png',
  },
  {
    champId: 'Yuumi',
    name: 'Yuumi',
    quote: 'the Magical Cat',
    image: '/champimages/Yuumi.png',
  },
  {
    champId: 'Zac',
    name: 'Zac',
    quote: 'the Secret Weapon',
    image: '/champimages/Zac.png',
  },
  {
    champId: 'Zed',
    name: 'Zed',
    quote: 'the Master of Shadows',
    image: '/champimages/Zed.png',
  },
  {
    champId: 'Zeri',
    name: 'Zeri',
    quote: 'The Spark of Zaun',
    image: '/champimages/Zeri.png',
  },
  {
    champId: 'Ziggs',
    name: 'Ziggs',
    quote: 'the Hexplosives Expert',
    image: '/champimages/Ziggs.png',
  },
  {
    champId: 'Zilean',
    name: 'Zilean',
    quote: 'the Chronokeeper',
    image: '/champimages/Zilean.png',
  },
  {
    champId: 'Zoe',
    name: 'Zoe',
    quote: 'the Aspect of Twilight',
    image: '/champimages/Zoe.png',
  },
  {
    champId: 'Zyra',
    name: 'Zyra',
    quote: 'Rise of the Thorns',
    image: '/champimages/Zyra.png',
  },
];
export default champions;
