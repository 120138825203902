import React, { useState, useEffect, useRef } from 'react';
import { ImageLoader } from '../build/boots/BootsImg';

type StatLineProps = {
  label: string;
  fighter1Stat: number;
  fighter2Stat: number;
};

const StatLine: React.FC<StatLineProps> = ({
  label,
  fighter1Stat,
  fighter2Stat,
}) => {
  const total = fighter1Stat + fighter2Stat;
  const percentage = total === 0 ? 0 : (fighter1Stat / total) * 100;

  return (
    <div className="flex flex-col items-center mb-2 shadow-strong-inner py-1 rounded-md shadow-cyan-500/10">
      {' '}
      {/* Reduced margin-bottom */}
      <div className="flex w-full mx-auto justify-between mb-1">
        <span className="text-xxs text-sky-300 ml-2">
          {label === 'Win Rate'
            ? fighter1Stat.toFixed(0) + '%'
            : fighter1Stat.toFixed(2)}
        </span>{' '}
        {/* Smaller text */}
        <span className="text-xxs text-white font-bold text-bold">
          {label}
        </span>{' '}
        {/* Smaller text */}
        <span className="text-xxs mr-2 text-green">
          {label === 'Win Rate'
            ? fighter2Stat.toFixed(0) + '%'
            : fighter2Stat.toFixed(2)}
        </span>{' '}
        {/* Smaller text */}
      </div>
      <div className="flex items-center w-full mx-auto">
        <div className="w-full h-1 mx-2 rounded-full bg-green border-1">
          {' '}
          {/* Reduced height */}
          <div
            style={{ width: `${percentage}%` }}
            className="h-1 rounded-full transition-all duration-500 bg-sky-400"
          ></div>
        </div>
      </div>
    </div>
  );
};

type ChampionItemProps = {
  name: string;
  winRate: number;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  marginRight?: boolean;
  addBorder?: boolean; // Add this line
  matchesPlayed: number;
};

const ChampionItem: React.FC<ChampionItemProps> = ({
  name,
  winRate,
  onClick,
  addBorder,
  matchesPlayed,
}) => (
  <div
    onClick={onClick}
    className={`cursor-pointer flex items-center justify-center p-1 border border-gray-800 shadow-inner hover:bg-primary rounded transition transform hover:scale-105`}
    style={{ minWidth: '4rem' }} // Set a minimum width for each item
  >
    <div className="flex flex-col items-center justify-center">
      <img
        src={`/champimages/${name}.png`}
        alt={name}
        className={`w-8 h-8 rounded-full shadow-sm shadow-cyan-500 border border-cyan-400`}
      />
      <div className="flex flex-col flex-grow">
        {' '}
        {/* Add margin-left */}
        <span className="text-xxs text-center text-white font-bold">
          {' '}
          {/* Use truncate to handle overflow */}
          {name}
        </span>
        <span className="text-xxs font-bold text-center text-win-rate">
          {Math.round(winRate * 100)}%
        </span>
      </div>
      <span className="text-[0.55rem]">Matches</span>
      <span className="text-[0.55rem]">{matchesPlayed}</span>
    </div>
  </div>
);

// Other imports remain the same...
type MatchupCardProps = {
  image1: string;
  goodAgainst: any[];
  badAgainst: any[];
  champid: string;
};

const MobileMatchupCard: React.FC<MatchupCardProps> = ({
  image1,
  goodAgainst,
  badAgainst,
  champid,
}) => {
  const [selectedChampion, setSelectedChampion] = useState<any>(null);
  const [image2, setImage2] = useState<string>('');
  const fighter2Ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (goodAgainst && goodAgainst.length > 0) {
      setSelectedChampion(goodAgainst[0]);
      setImage2(
        `/championCounter/${goodAgainst[0].opponentChampionName}_0.jpg`
      ); // Set initial image2
    } else if (badAgainst && badAgainst.length > 0) {
      setSelectedChampion(badAgainst[0]);
      setImage2(`/championCounter/${badAgainst[0].opponentChampionName}_0.jpg`); // Set initial image2
    }
  }, [goodAgainst, badAgainst]);

  useEffect(() => {
    if (goodAgainst && goodAgainst.length > 0) {
      setSelectedChampion(goodAgainst[0]);
      setImage2(
        `/championCounter/${goodAgainst[0].opponentChampionName}_0.jpg`
      );
    } else if (badAgainst && badAgainst.length > 0) {
      setSelectedChampion(badAgainst[0]);
      setImage2(`/championCounter/${badAgainst[0].opponentChampionName}_0.jpg`);
    }
  }, [goodAgainst, badAgainst]);

  const handleChampionClick = (
    champion: any,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    setSelectedChampion(champion);
    setImage2(`/championCounter/${champion.opponentChampionName}_0.jpg`);
  };

  const stats = selectedChampion
    ? [
        {
          label: 'Win Rate',
          fighter1Stat: 100 - selectedChampion.winRate * 100,
          fighter2Stat: selectedChampion.winRate * 100,
        },
        {
          label: 'KDA',
          fighter1Stat: selectedChampion.opponentKda,
          fighter2Stat: selectedChampion.kda,
        },
        {
          label: 'Early Gold Earned',
          fighter1Stat: selectedChampion.opponentEarlyGoldEarned,
          fighter2Stat: selectedChampion.earlyGoldEarned,
        },
        {
          label: 'Early Minions Killed',
          fighter1Stat: selectedChampion.opponentEarlyMinionsKilled,
          fighter2Stat: selectedChampion.earlyMinionsKilled,
        },
        {
          label: 'Early Solo Kills',
          fighter1Stat:
            selectedChampion.opponentEarlySoloKillsAgainstLaneOpponent,
          fighter2Stat: selectedChampion.earlySoloKillsAgainstLaneOpponent,
        },
      ]
    : [];

  // Adjustments for the mobile component start here
  return (
    <div className="fade-in-sm md:hidden p-2 rounded-md bg-secondary">
      {/* Bad Against Section */}
      {goodAgainst.length > 0 && (
        <>
          <h3 className="text-sm font-bold mb-1">Weak vs. {champid}</h3>
          <div className="flex flex-col overflow-x-auto overflow-y-hidden pb-2">
            <div className="flex gap-2">
              {goodAgainst.map((champ, index) => (
                <ChampionItem
                  key={champ.opponentChampionName}
                  name={champ.opponentChampionName}
                  winRate={champ.winRate}
                  matchesPlayed={champ.matchesPlayed}
                  onClick={(e) => handleChampionClick(champ, e)}
                  addBorder={index !== goodAgainst.length - 1}
                />
              ))}
            </div>
          </div>
        </>
      )}

      <div className="flex flex-col items-center bg-gradient-to-r from-slate-950 via-primary to-primary rounded-lg">
        <div className="flex items-center p-1">
          <ImageLoader
            src={image1}
            alt="Fighter 1"
            className="w-[20svh] h-auto max-w-[20%] rounded-lg shadow-sm shadow-sky-400 border-b-1  border-cyan-400"
          />

          <div className="flex-grow mx-3 flex flex-col gap-1">
            {stats.length > 0 ? (
              stats.map((stat, index) => (
                <StatLine
                  key={index}
                  label={stat.label}
                  fighter1Stat={stat.fighter2Stat}
                  fighter2Stat={stat.fighter1Stat}
                />
              ))
            ) : (
              <p className="text-white">No stats available</p>
            )}
          </div>

          <ImageLoader
            src={image2}
            alt="Fighter 2"
            className="w-[20svh] h-auto max-w-[20%] rounded-lg shadow-sm shadow-green border-b-1  border-green"
          />
        </div>
      </div>

      {/* Good Against Section */}

      {badAgainst.length > 0 && (
        <>
          <h3 className="text-sm font-bold mt-2">Strong vs. {champid}</h3>
          <div className="flex flex-col space-y-2 overflow-x-auto overflow-y-hidden pt-2">
            <div className="flex bg-gradient-to-r gap-2 from-primary via-your-middle-color to-secondary mb-2">
              {badAgainst.map((champ, index) => (
                <ChampionItem
                  key={champ.opponentChampionName}
                  name={champ.opponentChampionName}
                  winRate={champ.winRate}
                  matchesPlayed={champ.matchesPlayed}
                  onClick={(e) => handleChampionClick(champ, e)}
                  addBorder={index !== badAgainst.length - 1}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MobileMatchupCard;
