import React, { useState, useEffect } from 'react';
import { NormalItems } from '../../../firebase/championDAO';
import RecommendedItems from '../build/recommendedItems/RecommendedItems';
import ItemImg from '../build/recommendedItems/ItemImg';
import RightSvgArrow from '../build/recommendedItems/SvgArrow';

interface Item {
  id: number;
  name: string;
  category: string;
}

export interface ItemsTabProps {
  recommendedNormalItems: NormalItems[] | null;
  champid: string;
}

export const loadItemData = async () => {
  try {
    const module = await import(`../../../staticData/en_US/items/items`);
    return module.default;
  } catch (error) {
    console.error('Failed to load item data:', error);
    return {};
  }
};

const ItemsTab: React.FC<ItemsTabProps> = ({
  recommendedNormalItems,
  champid,
}) => {
  const [itemData, setItemData] = useState<any>({});
  const [sortCriterion, setSortCriterion] = useState<
    'winRate' | 'pickRate' | 'matches'
  >('matches');
  useEffect(() => {
    loadItemData().then((data) => {
      setItemData(data);
    });
  }, [recommendedNormalItems, champid]);

  if (recommendedNormalItems == null) {
    return <h1>Loading..</h1>;
  }

  // Sort items based on the selected criterion
  const sortedItems = [...recommendedNormalItems!].sort((a, b) => {
    if (sortCriterion === 'matches') {
      return b.matchesPlayed - a.matchesPlayed;
    } else {
      return b[sortCriterion] - a[sortCriterion];
    }
  });

  return (
    <div className="flex flex-col h-full rounded-md bg-primaryShade gap-1 md:p-3 w-full p-4">
      <div className="grid grid-cols-9 gap-1 items-center text-white text-1xs">
        <span className="col-span-3 font-semibold flex items-center">
          <div className="w-0.5 h-4 md:h-6 bg-green mr-1 md:mr-2"></div>
          <span className="text-sm md:text-lg font-bold">Item builds</span>
        </span>

        <div className="col-span-2 flex items-center">
          <label
            htmlFor="sort-select"
            className="hidden md:inline mr-2 text-white text-xs"
          >
            Sort by:
          </label>
          <div className="relative">
            <select
              id="sort-select"
              value={sortCriterion}
              onChange={(e) =>
                setSortCriterion(
                  e.target.value as 'winRate' | 'pickRate' | 'matches'
                )
              }
              className="bg-secondary text-white px-1 py-1 md:px-3 md:py-1.5 rounded w-20 md:w-40 text-xs appearance-none hover:bg-primary"
            >
              <option value="winRate">Win Rate</option>
              <option value="pickRate">Pick Rate</option>
              <option value="matches">Matches</option>
            </select>
          </div>
        </div>

        <div className="col-span-1 flex flex-col items-center text-win-rate border-r border-primary pr-2 pl-1 md:text-sm">
          <span>Win</span>
          <span>Rate</span>
        </div>

        <div className="col-span-1 flex flex-col items-center text-pick-rate border-r border-primary pr-2 pl-1 md:text-sm">
          <span>Pick</span>
          <span>Rate</span>
        </div>

        <div className="col-span-2 flex flex-col items-center text-matches md:text-lg">
          <span>Matches</span>
        </div>
      </div>

      <div className="flex flex-col gap-4 md:gap-0 md:mt-5">
        {sortedItems.map((normalItems, index) => (
          <div
            key={index}
            className={`grid grid-cols-9 md:gap-1 h-[2.2rem] md:h-[4.54rem] items-center md:py-1 ${
              index !== 0 ? 'border-t border-primary' : ''
            }`}
          >
            <div className="col-span-5 flex gap-2 items-center pt-2 md:pt-0">
              <ItemImg
                itemId={normalItems.item1}
                tooltipContent={
                  itemData['item_' + normalItems.item1]?.description || ''
                }
                header={itemData['item_' + normalItems.item1]?.name || ''}
                cost={`${
                  itemData['item_' + normalItems.item1]?.gold.total || ''
                } (${itemData['item_' + normalItems.item1]?.gold.base || ''})`}
              />
              <RightSvgArrow />
              <ItemImg
                itemId={normalItems.item2}
                tooltipContent={
                  itemData['item_' + normalItems.item2]?.description || ''
                }
                header={itemData['item_' + normalItems.item2]?.name || ''}
                cost={`${
                  itemData['item_' + normalItems.item2]?.gold.total || ''
                } (${itemData['item_' + normalItems.item2]?.gold.base || ''})`}
              />
              <RightSvgArrow />
              <ItemImg
                itemId={normalItems.item3}
                tooltipContent={
                  itemData['item_' + normalItems.item3]?.description || ''
                }
                header={itemData['item_' + normalItems.item3]?.name || ''}
                cost={`${
                  itemData['item_' + normalItems.item3]?.gold.total || ''
                } (${itemData['item_' + normalItems.item3]?.gold.base || ''})`}
              />
            </div>
            <div className="flex flex-col items-center col-span-1 text-win-rate text-sm-stats md:text-sm">
              <span>{Math.round(normalItems.winRate * 100)}%</span>
            </div>
            <div className="flex flex-col items-center text-sm-stats md:text-sm col-span-1 text-pick-rate">
              <span>{Math.round(normalItems.pickRate * 100)}%</span>
            </div>
            <div className="flex flex-col items-center text-sm-stats md:text-sm col-span-2 text-matches">
              <span>{normalItems.matchesPlayed}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItemsTab;
