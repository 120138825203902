import React, { useState, useEffect, useRef } from 'react';
import { ImageLoader } from '../build/boots/BootsImg';

type IllusionStyle = {
  src?: string;
  top?: number;
  left?: number;
  // ... other styles ...
};

type StatLineProps = {
  label: string;
  fighter1Stat: number;
  fighter2Stat: number;
};

const StatLine: React.FC<StatLineProps> = ({
  label,
  fighter1Stat,
  fighter2Stat,
}) => {
  const total = fighter1Stat + fighter2Stat;
  const percentage = total === 0 ? 0 : (fighter1Stat / total) * 100;

  return (
    <div className="flex flex-col items-center mb-4 w-full p-2 drop-shadow-2xl shadow-strong-inner py-1 rounded-md shadow-cyan-500/20">
      <div className="flex w-full mx-auto justify-between mb-1">
        <span className="lg:text-sm md:text-xs ml-2 text-sky-300">
          {label === 'Win Rate'
            ? fighter1Stat.toFixed(0) + '%'
            : fighter1Stat.toFixed(2)}
        </span>
        <span className="lg:text-sm text-white font-bold mb-2 md:text-xs lg:text-[1rem] text-bold">
          {label}
        </span>
        <span className="lg:text-sm md:text-xs mr-2 text-green">
          {label === 'Win Rate'
            ? fighter2Stat.toFixed(0) + '%'
            : fighter2Stat.toFixed(2)}
        </span>
      </div>
      <div className="flex items-center w-full mx-auto">
        <div className="w-full h-2 mx-2 rounded-full bg-green ">
          <div
            style={{ width: `${percentage}%` }}
            className="h-2 rounded-full transition-all duration-500 bg-sky-400"
          ></div>
        </div>
      </div>
    </div>
  );
};

type ChampionItemProps = {
  name: string;
  winRate: number;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  marginRight?: boolean;
  addBorder?: boolean; // Add this line
  matchesPlayed: number;
};

const ChampionItem: React.FC<ChampionItemProps> = ({
  name: championName,
  winRate,
  onClick,
  addBorder, // And this line
  matchesPlayed,
}) => (
  <div
    onClick={onClick}
    className={`cursor-pointer flex justify-center items-center p-2 hover:bg-primary rounded transition transform hover:scale-105 ${
      addBorder ? 'border-b border-primary' : ''
    }`}
  >
    <div className="flex flex-col items-center justify-center gap-1">
      <img
        src={`/champimages/${championName}.png`}
        alt={championName}
        className={`w-10 h-10 rounded-full shadow-2xl shadow-cyan-500 border border-cyan-400`}
      />
      <span className="text-sm">{championName}</span>
      <span className="text-win-rate text-sm">
        {Math.round(winRate * 100)}%
      </span>
      <p className="text-xs"> Matches</p>
      <p className="text-xs">{matchesPlayed}</p>
    </div>
  </div>
);

type MatchupCardProps = {
  image1: string;
  goodAgainst: any[];
  badAgainst: any[];
  champid: string;
};

const MatchupCard: React.FC<MatchupCardProps> = ({
  image1,
  goodAgainst,
  badAgainst,
  champid,
}) => {
  const [selectedChampion, setSelectedChampion] = useState<any>(null);
  const [image2, setImage2] = useState<string>(''); // Add a state for image2
  const [illusionStyle, setIllusionStyle] = useState<IllusionStyle>({});
  const fighter2Ref = useRef<HTMLImageElement>(null); // Specify the ref type

  useEffect(() => {
    if (goodAgainst && goodAgainst.length > 0) {
      setSelectedChampion(goodAgainst[0]);
      setImage2(
        `/championCounter/${goodAgainst[0].opponentChampionName}_0.jpg`
      ); // Set initial image2
    } else if (badAgainst && badAgainst.length > 0) {
      setSelectedChampion(badAgainst[0]);
      setImage2(`/championCounter/${badAgainst[0].opponentChampionName}_0.jpg`); // Set initial image2
    }
  }, [goodAgainst, badAgainst]);

  const handleChampionClick = (
    champion: any,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    setSelectedChampion(champion);
    setImage2(`/championCounter/${champion.opponentChampionName}_0.jpg`); // Update image2 on champion click

    const clickedElem = event.currentTarget; // or event.target, depending on your needs
    const clickedRect = clickedElem.getBoundingClientRect();
    if (fighter2Ref.current) {
      // Check if current is not null
      const targetRect = fighter2Ref.current.getBoundingClientRect();

      setIllusionStyle({
        src: `/champimages/${champion.name}.png`,
        top: clickedRect.top,
        left: clickedRect.left,
        // ... set other initial styles ...
      });

      // Trigger re-render to apply initial styles
      setTimeout(() => {
        setIllusionStyle({
          src: `/champimages/${champion.name}.png`,
          top: targetRect.top,
          left: targetRect.left,
          // ... set target styles ...
        });
      }, 0);
    }
  };

  const stats = selectedChampion
    ? [
        {
          label: 'Win Rate',
          fighter1Stat: 100 - selectedChampion.winRate * 100,
          fighter2Stat: selectedChampion.winRate * 100,
        },
        {
          label: 'KDA',
          fighter1Stat: selectedChampion.opponentKda,
          fighter2Stat: selectedChampion.kda,
        },
        {
          label: 'Early Gold Earned',
          fighter1Stat: selectedChampion.opponentEarlyGoldEarned,
          fighter2Stat: selectedChampion.earlyGoldEarned,
        },
        {
          label: 'Early Minions Killed',
          fighter1Stat: selectedChampion.opponentEarlyMinionsKilled,
          fighter2Stat: selectedChampion.earlyMinionsKilled,
        },
        {
          label: 'Early Solo Kills',
          fighter1Stat:
            selectedChampion.opponentEarlySoloKillsAgainstLaneOpponent,
          fighter2Stat: selectedChampion.earlySoloKillsAgainstLaneOpponent,
        },
      ]
    : [];

  return (
    <div className="fade-in-sm flex space-x-2 justify-center items-center w-full">
      {badAgainst.length > 0 && (
        <div className="overflow-y-auto max-h-[25rem] pt-0 direction-rtl bg-gradient-to-b to-yellow-500 rounded-lg shadow-lg p-2">
          <div className="sticky top-0 z-10 px-2 py-1 text-sm font-bold text-gray-800 bg-secondary border-b flex">
            <span className="flex-1 text-left font-bold text-white text-1xs md:text-sm lg:text-lg">
              <span className="block">Strong vs. {champid}</span>
            </span>
          </div>
          {badAgainst.map((champ, index) => (
            <ChampionItem
              key={champ.opponentChampionName}
              name={champ.opponentChampionName}
              winRate={champ.winRate}
              matchesPlayed={champ.matchesPlayed}
              onClick={(e) => handleChampionClick(champ, e)}
              marginRight={false}
              addBorder={index !== badAgainst.length - 1} // Add this line
            />
          ))}
        </div>
      )}

      <div
        style={{ borderColor: '#0f172a', borderWidth: '1px' }} // fattar ej ibland tailwind funkar ej så måste köra detta
        className="flex items-center w-full gap-4 p-6 bg-gradient-to-r from-secondary via-primary to-primary rounded-lg border-2 border-red-500 shadow-lg hover:shadow-2xl transition-shadow duration-500"
      >
        <ImageLoader
          src={image1} // This should be dynamically set from the parent component
          alt="Fighter 1"
          className="w-[20svh] h-auto max-w-[20%] rounded-lg shadow-sm shadow-sky-400 border-b-2  border-cyan-400"
        />
        <div className="flex flex-col flex-1 gap-4">
          {stats.length > 0 ? (
            stats.map((stat, index) => (
              <StatLine
                key={index}
                label={stat.label}
                fighter1Stat={stat.fighter2Stat}
                fighter2Stat={stat.fighter1Stat}
              />
            ))
          ) : (
            <p className="text-white">No stats available</p>
          )}
        </div>
        <ImageLoader
          src={image2} // This is now dynamically set based on selected champion
          alt="Fighter 2"
          className="w-[20svh] h-auto max-w-[20%] rounded-lg shadow-sm shadow-green border-b-2 border-green" // Change width from w-1/5 to w-1/6
        />
      </div>
      {goodAgainst.length > 0 && (
        <div className="overflow-y-auto max-h-[25rem] pt-0 bg-gradient-to-b from-green-500 to-blue-500 rounded-lg shadow-lg p-2">
          <div className="sticky top-0 z-10 px-2 py-1 text-sm font-bold text-gray-800 bg-secondary border-b flex">
            <span className="flex-1 text-right font-bold text-white text-1xs md:text-sm lg:text-lg">
              <span className="block">Weak vs. {champid}</span>
            </span>
          </div>

          {goodAgainst.map((champ, index) => (
            <ChampionItem
              key={champ.opponentChampionName}
              name={champ.opponentChampionName}
              winRate={champ.winRate}
              matchesPlayed={champ.matchesPlayed}
              onClick={(e) => handleChampionClick(champ, e)}
              marginRight={true}
              addBorder={index !== goodAgainst.length - 1} // Add this line
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default MatchupCard;
