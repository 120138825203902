import React, { useState, useEffect } from 'react';
import { loadRuneData, RuneData, runeShadowColors } from './RecommendedRunes';
import CustomTooltip from '../CustomToolTip';

interface SubStyleRunesProps {
  subStyle: number;
  subStylePerk1: number;
  subStylePerk2: number;
}

const SubStyleRunes: React.FC<SubStyleRunesProps> = ({
  subStyle,
  subStylePerk1,
  subStylePerk2,
}) => {
  const [subStyleData, setSubStyleData] = useState<RuneData>();

  useEffect(() => {
    loadRuneData(subStyle, 'secondary').then((data) => {
      setSubStyleData(data);
    });
  }, [subStyle, subStylePerk1, subStylePerk2]);

  if (!subStyleData) return <div></div>;

  const recommendedSubStyleRunes = [subStylePerk1, subStylePerk2];

  const isRuneRowRecommended = (slot: any) => {
    return slot.runes.every(
      (rune: any) => !recommendedSubStyleRunes.includes(rune.id)
    );
  };

  return (
    <div
      key={subStyle + subStylePerk1 + subStylePerk2}
      className="fade-in grid grid-rows-3 lg:grid-rows-4 place-items-center gap-2 lg:gap-y-4 lg:min-w-[11rem]"
    >
      <img
        src={`/${subStyleData.icon}`}
        alt="Description of Image"
        className="h-5 md:h-8 rounded"
      />
      {subStyleData.slots.map((slot, rowIndex) => (
        <div
          key={rowIndex + 'a'}
          className={`${isRuneRowRecommended(slot) ? 'hidden lg:block' : ''}`}
        >
          <div className="lg:flex lg:flex-row lg:gap-x-3 md:flex">
            {slot.runes.map((rune, colIndex) => (
              <CustomTooltip
                key={colIndex + 'b'}
                position="top"
                content={rune.longDesc}
                header={rune.name}
              >
                <img
                  src={`/${rune.icon}`}
                  alt="Description of Image"
                  className={`h-5 md:h-8 md:mt-1.5 lg:mt-0 rounded ${
                    !recommendedSubStyleRunes.includes(rune.id)
                      ? 'hidden lg:block grayscale opacity-30 shadow-sm shadow-gray-500 rounded-full'
                      : `shadow-md rounded-full ${runeShadowColors[subStyle]}`
                  }`}
                />
              </CustomTooltip>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SubStyleRunes;
