import React, { useState, useEffect } from 'react';
import { Runes } from '../../../../firebase/championDAO';
import SubStyleRunes from './SubStyleRunes';
import RuneMods from './RuneMods';
import PrimaryRunes from './PrimaryRunes';
import { RuneButtons } from './RuneButtons';

interface RuneShadowColor {
  [key: number]: string;
}

export const runeShadowColors: RuneShadowColor = {
  8000: 'shadow-yellow-900',
  8100: 'shadow-red-900',
  8200: 'shadow-blue-900',
  8300: 'shadow-teal-900',
  8400: 'shadow-emerald-900',
};

interface RecommendedRunesProps {
  recommendedRunes: Runes[];
  champId: string;
}

interface StaticDataRune {
  id: number;
  key: string;
  icon: string;
  name: string;
  shortDesc: string;
  longDesc: string;
}

interface Slot {
  runes: StaticDataRune[];
}

export interface RuneData {
  id: number;
  icon: string;
  name: string;
  isActive: boolean;
  slots: Slot[];
}

export const loadRuneData = async (
  primaryStyle: number,
  styleType: 'primary' | 'secondary'
) => {
  try {
    // Dynamic import based on runeId
    const module = await import(
      // TODO: en_US is the language picked, this should be dynamic based on the users current country maybe?
      // should we do this in build section? eg parent section
      `../../../../staticData/en_US/runes/r_${primaryStyle}_${styleType}.ts`
    );
    return module.default;
  } catch (error) {
    console.error('Failed to load rune data:', error);
    return null;
  }
};

const RecommendedRunes: React.FC<RecommendedRunesProps> = ({
  recommendedRunes,
  champId,
}) => {
  const [selectedRunesIndex, setSelectedRunesIndex] = useState(0);
  const [primaryStyleData, setPrimaryStyleData] = useState<RuneData>();
  const currentRunes = recommendedRunes.slice(0, 2)[selectedRunesIndex];

  useEffect(() => {
    setSelectedRunesIndex(0);
  }, [champId]);

  useEffect(() => {
    loadRuneData(currentRunes.primaryStyle, 'primary').then((data) => {
      setPrimaryStyleData(data);
    });
  }, [recommendedRunes, selectedRunesIndex]);

  if (!primaryStyleData) return <div className=""></div>;

  const currentPrimaryRunes = [
    currentRunes.primaryStylePerk1,
    currentRunes.primaryStylePerk2,
    currentRunes.primaryStylePerk3,
    currentRunes.primaryStylePerk4,
  ];

  return (
    <div className="flex items-end gap-4 min-h-[11.3rem] md:gap-0 lg:gap-6 md:w-full pb-4 pt-4 pl-1 pr-1 lg:h-[98%] md:h-[85%] lg:pb-8">
      <div className="w-[38%] md:w-[30%] lg:w-[38%]">
        <RuneButtons
          selectedRunesIndex={selectedRunesIndex}
          setSelectedRunesIndex={setSelectedRunesIndex}
          recommendedRunes={recommendedRunes}
        />
        <PrimaryRunes
          primaryStyleData={primaryStyleData}
          currentPrimaryRunes={currentPrimaryRunes}
        />
      </div>
      <div className="w-[38%] md:w-[25%] lg:w-[35%]">
        <SubStyleRunes
          subStyle={currentRunes.subStyle}
          subStylePerk1={currentRunes.subStylePerk1}
          subStylePerk2={currentRunes.subStylePerk2}
        />
      </div>
      <div className="w-[18%] mg:w-[25%] lg:w-[18%]">
        <RuneMods
          statMods={[
            currentRunes.statMod1,
            currentRunes.statMod2,
            currentRunes.statMod3,
          ]}
        />
      </div>
    </div>
  );
};

export default RecommendedRunes;
