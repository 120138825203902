import React from 'react';
import SearchBar from './components/ChampionSearchBar';

const ChampionPage: React.FC = () => {
    return (
        <div className="w-full area ">
            <div className="relative flex flex-col items-center ">
                <div className="w-full">
                    <div className="relative opacity-30 mt-3.5 scale-y-105 -mb-4" style={{ height: '110vh', overflow: 'hidden' }}>
                        {/* Video for desktop, image for mobile */}
                        <video
                            src="/mp4/2.mp4"
                            className="rounded-lg w-full object-cover filter blur-sm  hidden md:block"
                            muted
                            autoPlay
                            loop
                            controls={false}
                            style={{ minWidth: '100%', minHeight: '100%' }}
                        />
                        <img
                            src="championCounter/Ekko_28.jpg"
                            alt="Ekko Background"
                            className="rounded-lg scale-y-110  w-full object-cover filter blur-sm  block md:hidden"
                            style={{ minWidth: '100%', minHeight: '100%' }}
                        />
                    </div>
                    <div className="absolute top-1/4 left-1/2 transform -translate-x-1/2 z-10 font-proxima text-center">
                        <div className="text-5xl md:text-5xl font-proxima-nova font-extrabold mb-10 shadow-2xl">
                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-blue-700 drop-shadow-md">E</span>
                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-blue-700 drop-shadow-md">Z</span>
                            <span className="text-white">.GG</span>
                        </div>
                    </div>
                    <div className="absolute inset-0 flex items-center justify-center z-10 w-full mt-10">
                        <div className="w-4/5 sm:w-[50%] py-2 min-w-max ">
                            <SearchBar padding="py-2 mx-20" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChampionPage;