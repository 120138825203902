import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select, {
  ActionMeta,
  OptionProps,
  components,
  SingleValueProps,
  GroupBase,
  SingleValue,
  MultiValue,
} from "react-select";
import { FaSearch } from "react-icons/fa";
import champions from "../../staticData/AllChampions/champions_en_US";
import colors from "tailwindcss/colors";
import { request } from "http";

// Define a type for your option
type OptionType = {
  value: string;
  label: string;
  image: string;
  descr: string;
};

export type RegionOptionType = {
  value: string;
  label: string;
  image: string;
};

// Define region options and default region
export const regionOptions: RegionOptionType[] = [
  { value: "BR", label: "BR", image: "/regionimages/Brazil.png" },
  {
    value: "Global",
    label: "Global",
    image: "/regionimages/unknown.png",
  },
  {
    value: "EUN",
    label: "EUN",
    image: "/regionimages/Europe.png",
  },
  {
    value: "EUW",
    label: "EUW",
    image: "/regionimages/Europe.png",
  },
  {
    value: "LAN",
    label: "LAN",
    image: "/regionimages/Latin_America_North.png",
  },
  {
    value: "LAS",
    label: "LAS",
    image: "/regionimages/Latin_America_South.png",
  },
  {
    value: "NA",
    label: "NA",
    image: "/regionimages/North_America.png",
  },
  { value: "OC", label: "OC", image: "/regionimages/Oceania.png" },
  { value: "RU", label: "RU", image: "/regionimages/Russia.png" },
  { value: "TR", label: "TR", image: "/regionimages/Turkey.png" },
  { value: "JP", label: "JP", image: "/regionimages/Japan.png" },
  {
    value: "KR",
    label: "KR",
    image: "/regionimages/Republic_of_Korea.png",
  },
  {
    value: "PH",
    label: "PH",
    image: "/regionimages/Philippines.png",
  },
  {
    value: "SG",
    label: "SG",
    image: "/regionimages/Singapore.png",
  },
  { value: "TW", label: "TW", image: "/regionimages/Taiwan.png" },
  { value: "TH", label: "TH", image: "/regionimages/Thailand.png" },
  { value: "VN", label: "VN", image: "/regionimages/Vietnam.png" },
];

const defaultRegion: RegionOptionType = {
  value: "EUW",
  label: "EUW",
  image: "/regionimages/Europe.png",
};

// Define the options based on your champions
const options: OptionType[] = champions.map((champion) => ({
  value: champion.champId,
  label: champion.name,
  image: champion.image,
  descr: champion.quote,
}));

// Define a custom option component to show an image alongside the champion name
const CustomOption = (props: OptionProps<OptionType, false>) => (
  <components.Option {...props}>
    <div className="flex items-start w-full">
      <img
        src={props.data.image}
        alt={props.data.label}
        className="w-10 h-10 mr-1"
      />
      <div className="flex flex-col items-start">
        <div className="text-xs md:text-lg">{props.data.label}</div>
        <div className="text-xxs md:text-xs text-gray-400 italic">
          {props.data.descr}
        </div>
      </div>
    </div>
  </components.Option>
);

const RegionOption: React.FC<OptionProps<RegionOptionType, false>> = (
  props
) => {
  return (
    <components.Option {...props}>
      <div className="flex justify-between opacity-20">
        <div>{props.data.label}</div>
      </div>
    </components.Option>
  );
};

// Define a custom DropdownIndicator component that doesn't render anything
const DropdownIndicator = () => null;

// Define styles for the Select component
const customStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
  }),
  placeholder: (defaultStyles: any) => ({
    ...defaultStyles,
    textAlign: "left",
    fontSize: "0.8rem",
    "@media (min-width: 768px)": {
      fontSize: "1.05rem",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: colors.slate[800],
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? colors.gray[900] : "transparent",
    borderBottom: `1px solid ${colors.slate[900]}`,
    color: "white",
    borderTop: `1px solid ${colors.slate[900]}`,
    ":hover": {
      cursor: "pointer",
    },
  }),
  input: (provided: any) => ({
    ...provided,
    color: "white",
    fontSize: "16px",
    ":hover": {
      cursor: "text",
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "white",
  }),
};

const RegionSingleValue: React.FC<
  SingleValueProps<RegionOptionType, false>
> = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <div className="min-w-[5rem] md:w-full">
      <div className="text-xxs md:text-xs text-gray-500">Region</div>
      <div className="text-xxs md:text-sm">{children}</div>
    </div>
  </components.SingleValue>
);

const regionSelectStyles = {
  ...customStyles,
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    ":hover": {
      cursor: "pointer",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: colors.slate[800],
  }),
  placeholder: (defaultStyles: any) => ({
    ...defaultStyles,
    color: "white",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "white",
  }),
};

interface ChampionSearchBarProps {
  padding: string;
}

const ChampionSearchBar: React.FC<ChampionSearchBarProps> = ({ padding }) => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<RegionOptionType | null>(
    defaultRegion
  );
  const [summonerName, setSummonerName] = useState('');

  const handleChampionChange = (
    selectedOption: OptionType | null,
    meta: ActionMeta<OptionType>
  ) => {
    setSelectedOption(selectedOption);
    if (meta.action === "select-option") {
      let champnameWithoutStrings = selectedOption?.value;
      navigate(`/champion/${champnameWithoutStrings}`);
    }
  };

  const handleRegionChange = (
    selectedRegion:
      | SingleValue<RegionOptionType>
      | MultiValue<RegionOptionType>,
    meta: ActionMeta<RegionOptionType>
  ) => {
    const singleSelectedRegion = Array.isArray(selectedRegion)
      ? selectedRegion[0]
      : selectedRegion;
    setSelectedRegion(singleSelectedRegion);
  };

  const handleSearchClick = async () => {
    console.log("Summoner: " + summonerName)
    const [name, tag] = summonerName.split('#');
    const usedTagline = tag ? tag : selectedRegion?.label;
  
    const url = `/summoner/${selectedRegion?.value}/${name}/${usedTagline}`;
    navigate(url);
  }

  // @ts-ignore
  const handleInputChange = (inputValue: string, { action }) => {
    if (action === 'input-change') {
      console.log('Input changed:', inputValue);  // Debugging log
      setSummonerName(inputValue);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSearchClick()
    }
  };
  

  return (
    <div className="flex items-center justify-center w-full">
      <div
        className={`flex justify-between bg-grey border-2 border-greyborder2 rounded-full w-full ${padding} px-4 items-center`}
      >
        <div className="flex items-center justify-between w-full">
          <div className="w-full flex">
            <Select
              isOptionDisabled={() => false}
              isClearable={false}
              isSearchable={false}
              options={regionOptions}
              onChange={handleRegionChange}
              value={selectedRegion}
              styles={regionSelectStyles}
              placeholder=""
              menuPlacement="auto"
              components={{
                Option: RegionOption,
                SingleValue: RegionSingleValue,
                IndicatorSeparator: () => null,
              }}
            />
            <div className="text-xs md:text-base w-full">
            <Select
                isClearable
                isSearchable
                openMenuOnClick={true}
                options={options}
                onChange={handleChampionChange}
                onInputChange={handleInputChange}
                value={selectedOption}
                inputValue={summonerName} 
                components={{
                  Option: CustomOption,
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                styles={customStyles}
                onKeyDown={handleKeyDown}
                />
            </div>
            <button onClick={handleSearchClick} className="pl-2 self-center mr-5">
              <FaSearch />
            </button>
          </div>
        </div>
      </div>
    </div>
  );  
};

export default ChampionSearchBar;