import React from 'react';
import {
  adaptiveForceMod,
  armorMod,
  attackSpeedMod,
  abilityHasteMod,
  healthMod,
  magicResistMod,
} from '../../../../staticData/en_US/runes/r_mods';
import CustomTooltip from '../CustomToolTip';

interface RuneModsProps {
  statMods: Array<Number>;
}

//tog bort koden innan och la in detta för att vi hade en massa duplicates. mycket mindre kod nu
const renderRuneMod = (
  mod: any,
  statModIndex: number,
  tooltipContent: string,
  statMods: Array<Number>,
  name: string
) => {
  return (
    <CustomTooltip position="top" header={name} content={tooltipContent}>
      <div
        className={`h-5 w-5 md:h-7 md:w-auto lg:w-7 lg:h-7 shadow-sm  rounded-full ${
          statMods[statModIndex] === mod.id
            ? ''
            : 'hidden lg:block grayscale opacity-20 shadow-sm'
        } 
        ${name === 'Attack Speed' && 'shadow-yellow-900'}
        ${name === 'Adaptive Force' && 'shadow-purple-900'}
        ${name === 'Armor' && 'shadow-pink-950'}
        ${name === 'Ability Haste' && 'shadow-gray-900'}
        ${name === 'Magic Resist' && 'shadow-blue-900'}
        ${name === 'Health Scaling' && 'shadow-emerald-900'}
        `}
      >
        <img
          src={mod.iconPath}
          alt={`stat mod ${statModIndex + 1}`}
          className="max-w-full max-h-full"
        />
      </div>
    </CustomTooltip>
  );

  // return <CustomTooltip position="top" header={name} content={tooltipContent}>
  //   <img
  //     src={mod.iconPath}
  //     alt={`stat mod ${statModIndex + 1}`}
  //     className={`h-5 md:w-auto md:h-auto ${
  //       statMods[statModIndex] === mod.id
  //         ? ''
  //         : 'hidden md:block grayscale opacity-20'
  //     }`}
  //   />
  //</CustomTooltip>
};

const RuneMods: React.FC<RuneModsProps> = ({ statMods }) => {
  return (
    <>
      <div
        key={statMods.join()}
        className="fade-in grid lg:grid-cols-3 grid-cols-1 grid-rows-1 lg:grid-rows-3 place-items-center"
      >
        {/* first row */}
        {renderRuneMod(
          adaptiveForceMod,
          2,
          adaptiveForceMod.longDesc,
          statMods,
          adaptiveForceMod.name
        )}
        {renderRuneMod(
          attackSpeedMod,
          2,
          attackSpeedMod.longDesc,
          statMods,
          attackSpeedMod.name
        )}
        {renderRuneMod(
          abilityHasteMod,
          2,
          abilityHasteMod.longDesc,
          statMods,
          abilityHasteMod.name
        )}
        {/* second row */}
        {renderRuneMod(
          adaptiveForceMod,
          1,
          adaptiveForceMod.longDesc,
          statMods,
          adaptiveForceMod.name
        )}
        {renderRuneMod(armorMod, 1, armorMod.longDesc, statMods, armorMod.name)}
        {renderRuneMod(
          magicResistMod,
          1,
          magicResistMod.shortDesc,
          statMods,
          magicResistMod.name
        )}
        {/* third row */}
        {renderRuneMod(
          healthMod,
          0,
          healthMod.longDesc,
          statMods,
          healthMod.name
        )}
        {renderRuneMod(armorMod, 0, armorMod.longDesc, statMods, armorMod.name)}
        {renderRuneMod(
          magicResistMod,
          0,
          magicResistMod.shortDesc,
          statMods,
          magicResistMod.name
        )}
      </div>
    </>
  );
};

export default RuneMods;
