import React from 'react';

import { Opponent } from '../../../../firebase/championDAO';
import OpponentsComponent from './components/OpponentsComponent';

interface RecommendedOpponentsProps {
  opponents: Opponent[];
  title: string;
}

const RecommendedOpponents: React.FC<RecommendedOpponentsProps> = ({
  opponents,
  title,
}) => {
  return opponents.length > 0 ? (
    <div className="w-97/100 md:w-[23.8rem] lg:w-[23rem] rounded-md bg-primaryShade">
      <div className="flex flex-row items-center ">
        <div className="w-0.5 h-6 bg-green ml-2 mt-2"></div>
        <p className="text-sm md:text-lg font-bold ml-2 mt-2">{title}</p>
      </div>
      <div className="flex items-center mb-3 mt-3">
        <OpponentsComponent opponents={opponents} />
      </div>
    </div>
  ) : null;
};

export default RecommendedOpponents;
