import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './lol-landing-page/LandingPage';
import ChampionPage from './lol-champion-page/ChampionPage';
import Summoner from './lol-summoner-page/SummonerPage';
import Header from './components/header';
import Footer from './components/footer';
import './styles/tailwind.css';

function App() {
  return (
    <div className="App bg-primary flex flex-col min-h-screen text-white overflow-x-hidden">
      <Router>
        <Header />
        <div className="flex-grow mb-8">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/champion/:id" element={<ChampionPage />} />
            <Route path="/summoner/:region/:summonername/:tagline" element={<Summoner />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
