import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
  
  const Footer = () => {
    return (
      <footer className="bg-slate-950 text-white border-t border-gray-800 py-6">
        <div className="container mx-auto flex flex-wrap p-4 md:p-5 flex-col md:flex-row items-center">
          <div className="flex-grow">
            <h1 className="text-xl md:text-2xl font-bold font-proxima">EZ.GG</h1>
          </div>
          <div className="flex flex-wrap items-center text-sm md:text-base justify-center">
            <a href="#" className="mr-3 md:mr-5 hover:text-gray-400 font-proxima">
              Privacy Policy
            </a>
            <a href="#" className="mr-3 md:mr-5 hover:text-gray-400 font-proxima">
              Terms of Service
            </a>
          </div>
        </div>
        <hr className="border-gray-700 w-full my-4 mx-auto" style={{ width: '80%', opacity: 0.6 }} />
        <div className="container mx-auto flex flex-col items-center text-center mt-4">
          <div className="flex justify-center space-x-4 mb-4">
            <a href="#" className="text-white hover:text-gray-400">
              <FaInstagram size={24} />
            </a>
            <a href="#" className="text-white hover:text-gray-400">
              <FaTiktok size={24} />
            </a>
            <a href="#" className="text-white hover:text-gray-400">
              <FaYoutube size={24} />
            </a>
          </div>
          <p className="font-proxima text-sm md:text-base">
            EZ.GG 2024-2024 is not endorsed by Riot Games and does not reflect the views or opinions of Riot Games or anyone officially involved in producing or managing
            League of Legends. League of Legends and Riot Games are trademarks or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc.
          </p>
        </div>
      </footer>
    );
  };
  
  export default Footer;
