import React, { FC, useEffect, useState } from 'react';
import ItemImg from './ItemImg';
import RightSvgArrow from './SvgArrow';
import { NormalItems } from '../../../../firebase/championDAO';

interface RecommendedItemsProps {
  recommendedNormalItems: NormalItems[] | null;
  itemData: any;
}

const RecommendedItems: FC<RecommendedItemsProps> = ({
  recommendedNormalItems,
  itemData,
}) => {
  // This is for showing the highest winrate item first in the list
  recommendedNormalItems = recommendedNormalItems!.slice(0, 5);

  if (recommendedNormalItems == null) {
    return <h1>Loading..</h1>;
  }

  return (
    <div className="flex flex-col flex-shrink-1 rounded-md bg-primaryShade gap-1 md:p-3 w-97/100 md:w-96 p-4">
      <div className="grid grid-cols-9 gap-1 items-center text-white text-1xs">
        <span className="col-span-5 font-semibold flex items-center">
          <div className="w-0.5 h-4 md:h-6 bg-green mr-1 md:mr-2"></div>
          <span className="text-sm md:text-lg font-bold"> Item builds</span>
        </span>
        <div className="flex flex-col lg:text-xs items-center col-span-1 text-win-rate border-r border-primary pr-2 pl-1">
          <span>Win</span>
          <span>Rate</span>
        </div>
        <div className="flex flex-col lg:text-xs items-center col-span-1 text-pick-rate border-r border-primary pr-2 pl-1">
          <span>Pick</span>
          <span>Rate</span>
        </div>
        <div className="flex flex-col lg:text-xs items-center col-span-2 border-r border-transparent text-matches">
          <span>Matches</span>
        </div>
      </div>

      <div className="flex flex-col gap-4 md:gap-1 md:mt-5">
        {recommendedNormalItems.map((normalItems, index) => (
          <div
            key={index}
            className={`grid grid-cols-9 md:gap-1 h-[2.2rem] md:h-[4.54rem] items-center md:py-1 ${
              index !== 0 ? 'border-t border-primary' : ''
            }`}
          >
            <div className="col-span-5 flex gap-2 items-center pt-2 md:pt-0">
              <ItemImg
                itemId={normalItems.item1}
                tooltipContent={
                  itemData['item_' + normalItems.item1]?.description || ''
                }
                header={itemData['item_' + normalItems.item1]?.name || ''}
                cost={`${
                  itemData['item_' + normalItems.item1]?.gold.total || ''
                } (${itemData['item_' + normalItems.item1]?.gold.base || ''})`}
              />
              <RightSvgArrow />
              <ItemImg
                itemId={normalItems.item2}
                tooltipContent={
                  itemData['item_' + normalItems.item2]?.description || ''
                }
                header={itemData['item_' + normalItems.item2]?.name || ''}
                cost={`${
                  itemData['item_' + normalItems.item2]?.gold.total || ''
                } (${itemData['item_' + normalItems.item2]?.gold.base || ''})`}
              />
              <RightSvgArrow />
              <ItemImg
                itemId={normalItems.item3}
                tooltipContent={
                  itemData['item_' + normalItems.item3]?.description || ''
                }
                header={itemData['item_' + normalItems.item3]?.name || ''}
                cost={`${
                  itemData['item_' + normalItems.item3]?.gold.total || ''
                } (${itemData['item_' + normalItems.item3]?.gold.base || ''})`}
              />
            </div>
            <div className="flex flex-col items-center col-span-1 text-win-rate text-sm-stats md:text-xs">
              <span>{Math.round(normalItems.winRate * 100)}%</span>
            </div>
            <div className="flex flex-col items-center text-sm-stats md:text-xs col-span-1 text-pick-rate">
              <span>{Math.round(normalItems.pickRate * 100)}%</span>
            </div>
            <div className="flex flex-col items-center text-sm-stats md:text-xs col-span-2 text-matches">
              <span>{normalItems.matchesPlayed}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecommendedItems;
