import React, { FC } from 'react';
import { StartingItems } from '../../../../firebase/championDAO';
import ItemImg from '../recommendedItems/ItemImg';

interface StartingItemsAndSpellsProps {
  recommendedStartingItems: StartingItems[];
  itemData: any;
}

const countOccurrences = (arr: number[]) => {
  return arr.reduce(
    (acc, val) => {
      acc[val] = (acc[val] || 0) + 1;
      return acc;
    },
    {} as Record<number, number>
  );
};

const StartingItemsAndSpells: FC<StartingItemsAndSpellsProps> = ({
  recommendedStartingItems,
  itemData,
}) => {
  return (
    <div className="flex flex-col h-full p-2 rounded-md bg-primaryShade self-start w-full md:max-h-[9rem]">
      {/* Header Section */}
      <div className="grid grid-cols-4 gap-y-2 items-center text-white text-1xs">
        <span className="font-semibold flex items-center justify-self-start">
          <div className="w-0.5 h-4 md:h-6 bg-green mr-1 md:mr-2"></div>
          <span className="text-sm md:text-lg font-bold">Items</span>
        </span>
        <div className="flex text-xxs flex-col items-center lg:text-xs text-win-rate border-r border-primary pr-2 pl-1">
          <span>Win</span>
          <span>Rate</span>
        </div>
        <div className="flex text-xxs flex-col items-center lg:text-xs text-pick-rate border-r border-primary pr-2 pl-1">
          <span>Pick</span>
          <span>Rate</span>
        </div>
        <div className="flex text-xxs flex-col items-center lg:text-xs text-matches border-r border-transparent">
          <span>Matches</span>
        </div>

        {recommendedStartingItems.map((item, index) => {
          const itemCounts = countOccurrences(item.startingItems);
          const uniqueStartingItems = [...new Set(item.startingItems)]; // This will give you an array of unique item IDs
          return (
            <React.Fragment key={index}>
              <div className="flex flex-row gap-2 justify-start">
                {uniqueStartingItems.map((i) => (
                  <ItemImg
                    header={itemData['item_' + i]?.name || ''}
                    key={i}
                    itemId={i}
                    count={itemCounts[i]}
                    tooltipContent={itemData['item_' + i]?.description || ''}
                    cost={`${itemData['item_' + i]?.gold.total || ''} (${
                      itemData['item_' + i]?.gold.base || ''
                    })`}
                  />
                ))}
              </div>
              <span className="text-sm-stats md:text-2xs text-win-rate text-center">
                {Math.round(item.winRate * 100)}%
              </span>
              <span className="text-sm-stats md:text-2xs text-pick-rate text-center">
                {Math.round(item.pickRate * 100)}%
              </span>
              <span className="text-sm-stats md:text-2xs text-matches text-center">
                {item.matchesPlayed}
              </span>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default StartingItemsAndSpells;
