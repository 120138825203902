import React from 'react';
import { RuneData, runeShadowColors } from './RecommendedRunes'; // Make sure to provide the correct path
import CustomTooltip from '../CustomToolTip';

interface PrimaryRunesProps {
  primaryStyleData: RuneData;
  currentPrimaryRunes: number[];
}

const PrimaryRunes: React.FC<PrimaryRunesProps> = ({
  primaryStyleData,
  currentPrimaryRunes,
}) => {
  return (
    <div
      key={currentPrimaryRunes.join()}
      className="fade-in grid grid-rows-4 place-items-center gap-2 lg:h-[15rem]"
    >
      <div className="grid grid-cols-1 h-5 w-5 md:h-9 md:w-9 items-center place-items-center">
        <img
          src={`/${primaryStyleData.icon}`}
          alt="Description of Image"
          className="max-w-full max-h-full rounded"
        />
      </div>

      {primaryStyleData.slots[0].runes.length === 4 ? (
        <div className={'lg:grid lg:grid-cols-4'}>
          {primaryStyleData.slots[0].runes.map((rune, index) => (
            <CustomTooltip
              key={index + 'A'}
              position="top"
              content={rune.longDesc}
              header={rune.name}
            >
              <div
                className={`h-6 md:h-10 w-full ${
                  !currentPrimaryRunes.includes(rune.id)
                    ? 'hidden lg:block grayscale opacity-20 shadow-sm shadow-gray-500 rounded-full'
                    : `shadow-sm ${
                        runeShadowColors[primaryStyleData.id]
                      } rounded-full`
                }`}
              >
                <img
                  key={index + 'B'}
                  src={`/${rune.icon}`}
                  alt="Description of Image"
                  className="max-w-[110%] max-h-[110%] rounded"
                />
              </div>
            </CustomTooltip>
          ))}
        </div>
      ) : (
        <div className={'lg:grid lg:grid-cols-3'}>
          {primaryStyleData.slots[0].runes.map((rune, index) => (
            <CustomTooltip
              key={index + 'C'}
              position="top"
              content={rune.longDesc}
              header={rune.name}
            >
              <div
                className={`h-6 md:h-10 w-full ${
                  !currentPrimaryRunes.includes(rune.id)
                    ? 'hidden lg:block grayscale opacity-20 shadow-sm shadow-gray-500 rounded-full'
                    : `shadow-sm ${
                        runeShadowColors[primaryStyleData.id]
                      } rounded-full`
                }`}
              >
                <img
                  key={index + 'D'}
                  src={`/${rune.icon}`}
                  alt="Description of Image"
                  className="max-w-[110%] max-h-[110%] rounded"
                />
              </div>
            </CustomTooltip>
          ))}
        </div>
      )}

      {primaryStyleData.slots.slice(1).map((slot, rowIndex) =>
        slot.runes.length == 4 ? (
          <div key={rowIndex + 'Z'} className={'lg:grid lg:grid-cols-4 gap-2'}>
            {slot.runes.map((rune, colIndex) => (
              <CustomTooltip
                key={colIndex + 'E'}
                position="top"
                content={rune.longDesc}
                header={rune.name}
              >
                <div
                  className={`h-5 md:h-7  ${
                    !currentPrimaryRunes.includes(rune.id)
                      ? 'hidden lg:block grayscale opacity-30 shadow-ms shadow-gray-500 rounded-full'
                      : `shadow-md ${
                          runeShadowColors[primaryStyleData.id]
                        } rounded-full`
                  }`}
                >
                  <img
                    key={colIndex + 'F'}
                    src={`/${rune.icon}`}
                    alt="Description of Image"
                    className="max-w-full max-h-full rounded"
                  />
                </div>
              </CustomTooltip>
            ))}
          </div>
        ) : (
          <div
            key={rowIndex + 'G'}
            className={'lg:grid lg:grid-cols-3 lg:gap-2'}
          >
            {slot.runes.map((rune, colIndex) => (
              <CustomTooltip
                key={colIndex + 'Ö'}
                position="top"
                content={rune.longDesc}
                header={rune.name}
              >
                <div
                  className={`h-5 md:h-8  ${
                    !currentPrimaryRunes.includes(rune.id)
                      ? 'hidden lg:block grayscale opacity-30 shadow-sm shadow-gray-500 rounded-full'
                      : `shadow-md ${
                          runeShadowColors[primaryStyleData.id]
                        } rounded-full`
                  }`}
                >
                  <img
                    key={colIndex + 'I'}
                    src={`/${rune.icon}`}
                    alt="Description of Image"
                    className="max-w-full max-h-full rounded"
                  />
                </div>
              </CustomTooltip>
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default PrimaryRunes;
