import React, { FC } from 'react';
import CustomTooltip from '../CustomToolTip';

interface SpellImgProps {
  spellId: number;
  tooltipContent?: string; // Add this line
  header: string;
}

const SpellImg: FC<SpellImgProps> = ({ spellId, tooltipContent, header }) => {
  return (
    <CustomTooltip
      position="top"
      header={header}
      content={tooltipContent || ''}
    >
      <div className="relative">
        <img
          src={`/spell/${spellId}.png`}
          alt=""
          className="h-8 md:h-10 rounded"
        />
      </div>
    </CustomTooltip>
  );
};

export default SpellImg;
