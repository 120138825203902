import React, { FC, useEffect, useState } from 'react';
import { SkillLevelUp } from '../../../../firebase/championDAO';
import ChampionSpellsImages from './ChampionSpellsImages';
import RightSvgArrow, { SkillOrderArrow } from '../recommendedItems/SvgArrow';
import CustomTooltip from '../CustomToolTip';

// jag vet... vill också spy när jag ser denna komponent
// fixar så att de blir mer concise sen...

interface SkillOrderProps {
  order: SkillLevelUp;
  champid: String;
}

interface ChampionSpell {
  id: string;
  name: string;
  description: string;
  image: { full: string };
  // ... any other properties you expect
}

interface ChampionData {
  spells: ChampionSpell[];
  // ... any other properties you expect
}

type SkillLabelsType = {
  [key: string]: string;
  '1': string;
  '2': string;
  '3': string;
  '4': string;
};

const skillLabels: SkillLabelsType = {
  '1': 'Q',
  '2': 'W',
  '3': 'E',
  '4': 'R',
};

type CountMap = {
  [key: string]: number;
};

function calculateLastDigit(skillLevelUpOrder: string): string {
  const counts: CountMap = { '1': 0, '2': 0, '3': 0, '4': 0 };
  const maxCounts: CountMap = { '1': 5, '2': 5, '3': 5, '4': 3 };

  for (let char of skillLevelUpOrder) {
    if (counts[char] !== undefined) {
      counts[char]++;
    }
  }

  for (let key in counts) {
    if (counts[key] < maxCounts[key]) return key;
  }

  return '1'; // Default return
}

function determineSkillOrder(skillLevelUpOrder: string): string[] {
  const counts: CountMap = { '1': 0, '2': 0, '3': 0, '4': 0 };
  const maxCounts: CountMap = { '1': 4, '2': 4, '3': 4, '4': 4 };
  const order: string[] = [];

  for (let char of skillLevelUpOrder) {
    if (char in counts) {
      counts[char]++;

      // Check if the skill reached its max count for the first time
      if (counts[char] === maxCounts[char] && !order.includes(char)) {
        order.push(char);

        // Stop if three unique skills have reached their max count
        if (order.length === 3) {
          break;
        }
      }
    }
  }
  return order;
}

const SkillOrder: FC<SkillOrderProps> = ({ order, champid }) => {
  const skillColors = ['bg-green', 'bg-green', 'bg-green', 'bg-green'];
  const lastDigit = calculateLastDigit(order.skillLevelUpOrder);
  const fullSkillOrder = order.skillLevelUpOrder + lastDigit;
  const skillOrder1 = determineSkillOrder(fullSkillOrder);
  const skillOrderArray = Object.values(skillLabels);
  const [championData, setChampionData] = useState<ChampionData | null>(null);

  useEffect(() => {
    // Assuming champid is the champion name
    import(`../../../../staticData/en_US/champions/${champid}.ts`)
      .then((module) => {
        setChampionData(module.default);
      })
      .catch((error) => {
        console.error('Failed to load champion data:', error);
      });
  }, [champid]);
  if (!championData) return <h1></h1>; // or a loading spinner

  const skillDescriptions = championData.spells.map(
    (spell) => spell.description
  );
  const skillImagesPath = championData.spells.map((spell) => spell.image.full);

  const skillNames = championData.spells.map((spell) => spell.name);

  return (
    <div className="p-2 rounded-lg bg-primaryShade self-start w-full lg:w-[35.5rem] lg:pb-18 lg:pl-4 pt-2 pb-[0.85rem]">
      <div className="flex lg:mb-2 items-center lg:items-start lg:justify-start lg:gap-32">
        <div className="flex flex-col w-full">
          <div className="flex items-center mb-2 pb-[0.3rem]">
            {/* Vertical line (indicator) */}
            <div className="w-0.5 h-4 lg:h-6 bg-green mr-1 lg:mr-2"></div>

            {/* Your text */}
            <p className="text-sm md:text-lg font-bold">Skill order</p>
          </div>

          {/* Display the skill order images */}
          <div className="flex flex-col md:flex-row gap-2 mb-2 self-center">
            {skillOrder1.flatMap((skill, index) => {
              const spellIndex = parseInt(skill) - 1; // Convert '1' to 0, '2' to 1, etc.
              const spellDescription =
                championData.spells[spellIndex].description;
              const spellName = championData.spells[spellIndex].name;
              //add spell cooldown to tooltip for spells!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
              return (
                <div
                  key={index}
                  className="flex flex-col md:flex-row gap-1 items-center justify-center md:items-start md:justify-start"
                >
                  <CustomTooltip
                    position="top"
                    key={index}
                    content={spellDescription}
                    header={spellName}
                  >
                    <div className="relative h-6 w-6 md:w-auto md:h-9">
                      <img
                        src={`/spell/${skillImagesPath[spellIndex]}`}
                        alt={`Skill Order ${skillLabels[skill]}`}
                        className="rounded w-full h-full"
                      />
                      {/* Skill label (Q, W, E, R) */}
                      <span className="absolute bottom-0 left-0 transform -translate-x-1/2 translate-y-1/2 bg-greyborder2 text-black rounded-full px-0.5 text-xs lg:text-xxs text-white flex items-center justify-center w-2 h-2 md:w-4 md:h-4">
                        {skillLabels[skill]}
                      </span>
                    </div>
                  </CustomTooltip>
                  {index !== skillOrder1.length - 1 && (
                    <SkillOrderArrow key={`arrow-${index}`} />
                  )}
                </div>
              );
              // index !== skillOrder1.length - 1 && (
              //   <RightSvgArrow key={`arrow-${index}`} />
              // )
            })}
          </div>
        </div>

        <div className="hidden lg:items-center lg:mt-4">
          <div className="flex items-center">
            <div className="flex flex-col items-center py-1">
              <span className="text-green text-xxs">Win Rate</span>
              <span className="text-xs text-green">
                {Math.round(order.winRate * 100)}%
              </span>
            </div>
            <div className="h-10 w-0.5 bg-primary mx-7"></div>{' '}
            {/* Line indicator with equal margins */}
            <div className="flex flex-col items-center py-1">
              <span className="text-red text-xxs">Pick Rate</span>
              <span className="text-xs text-red">
                {Math.round(order.pickRate * 100)}%
              </span>
            </div>
            <div className="h-10 w-0.5 bg-primary mx-7"></div>{' '}
            {/* Line indicator with equal margins */}
            <div className="flex flex-col items-center py-1">
              <span className="text-white text-xxs">Matches</span>
              <span className="text-xs">{order.matchesPlayed}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden lg:flex lg:justify-center">
        {/* Champion Spells Images */}
        <ChampionSpellsImages
          icons={skillImagesPath}
          skilllabels={skillOrderArray}
          header={skillNames}
          tooltipContents={skillDescriptions}
        />

        {/* Skill Order Grid */}
        <div className=" lg:flex lg:flex-col gap-1 ml-4">
          {Object.keys(skillLabels).map((key, rowIndex) => (
            <div key={rowIndex} className="flex gap-1">
              {Array.from({ length: 17 }).map((_, colIndex) => (
                <div
                  key={colIndex}
                  className={`w-6 h-6 flex items-center justify-center text-primary font-semibold rounded ${
                    parseInt(fullSkillOrder[colIndex]) === rowIndex + 1
                      ? skillColors[rowIndex]
                      : 'bg-grey opacity-40'
                  }`}
                >
                  {parseInt(fullSkillOrder[colIndex]) === rowIndex + 1 &&
                    skillLabels[key]}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SkillOrder;
