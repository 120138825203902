import { Runes } from '../../../../firebase/championDAO';
import React, { useState, useEffect } from 'react';
import { RuneData } from './RecommendedRunes';

interface RuneButtonsProps {
  selectedRunesIndex: number;
  setSelectedRunesIndex: (index: number) => void;
  recommendedRunes: Runes[];
}

interface RuneImages {
  [key: number]: string;
}

const runeImages: RuneImages = {
  8000: '/perk-images/Styles/7201_Precision.png',
  8100: '/perk-images/Styles/7200_Domination.png',
  8200: '/perk-images/Styles/7202_Sorcery.png',
  8300: '/perk-images/Styles/7203_Whimsy.png',
  8400: '/perk-images/Styles/7204_Resolve.png',
};

export const RuneButtons: React.FC<RuneButtonsProps> = ({
  selectedRunesIndex,
  setSelectedRunesIndex,
  recommendedRunes,
}) => {
  recommendedRunes = recommendedRunes.slice(0, 2);
  return (
    <div
      key={
        recommendedRunes[0].primaryStyle +
        recommendedRunes[0].subStyle +
        recommendedRunes[0].styleWinRate +
        recommendedRunes[0].stylePickRate +
        recommendedRunes[0].matchesPlayed +
        recommendedRunes[1].primaryStyle +
        recommendedRunes[1].subStyle +
        recommendedRunes[1].styleWinRate +
        recommendedRunes[1].stylePickRate +
        recommendedRunes[1].matchesPlayed
      }
      className="fade-in relative md:w-auto"
    >
      <div className="absolute -right-[4.9rem] top-[-1.5rem] md:-right-[15rem] md:-top-[4rem] lg:left-[16rem] lg:-top-14">
        <div className="flex flex-col lg:flex-row gap-2 w-full">
          {recommendedRunes.map((recommendedRune, index) => {
            return (
              <button
                key={index}
                className={`bg-primary text-1xs md:text-base pl-2 py-[0.1rem] md:py-3 md:px-3 lg:py-4 lg:px-3 rounded border-2 ${
                  selectedRunesIndex === index
                    ? 'border-green bg-primary border-[0.05rem] pt-1'
                    : 'border-transparent opacity-60'
                }`}
                onClick={() => setSelectedRunesIndex(index)}
              >
                <div className="flex items-center space-x-2">
                  <div className="flex items-center space-x-1 w-full">
                    <div className="flex flex-col items-center gap-1 lg:gap-2">
                      <img
                        src={runeImages[recommendedRune.primaryStyle]}
                        alt=""
                        className="h-3 w-3 md:h-5 md:w-5"
                      />
                      <span className="text-xxs md:text-xs text-win-rate">
                        {Math.round(recommendedRune.styleWinRate * 100)}%
                      </span>
                    </div>
                    <div className="w-0.5 h-4 bg-primary"></div>{' '}
                    <div className="flex flex-col items-center gap-1 lg:gap-2">
                      <img
                        src={runeImages[recommendedRune.subStyle]}
                        alt=""
                        className="h-3 w-3 md:h-5 md:w-5"
                      />
                      <span className="text-xxs md:text-xs text-pick-rate">
                        {Math.round(recommendedRune.stylePickRate * 100)}%
                      </span>
                    </div>
                  </div>
                  <div className="w-0.5 h-4 bg-primary"></div>{' '}
                  <div className="hidden md:flex md:flex-col items-center space-y-0">
                    <span className="text-1xs text-matches md:text-xs">
                      Matches
                    </span>
                    <span className="text-sm-stats md:text-xs">
                      {recommendedRune.matchesPlayed}
                    </span>
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
