import React from 'react';
import getChampion from '../../../firebase/championDAO';

interface MatchHistoryItemProps {
  matchIndex: number;
}

const MatchHistoryItem: React.FC<MatchHistoryItemProps> = ({ matchIndex }) => {
  const isVictory = true; // Hardcoded to defeat for this example
  const items = [
    '/item/1040.png',
    '/item/1004.png',
    '/item/1006.png',
    '/item/1011.png',
    '/item/1018.png',
    '/item/1027.png',
  ]; // Array of item image paths

  return (
    <div
      className={`border border-secondary p-2 rounded transform transition-transform duration-500 hover:-translate-y-1 hover:scale-105 hover:border-blue-500 hover:shadow-lg flex items-start relative 
      ${
        isVictory
          ? 'bg-gradient-to-r from-green via-secondary to-secondary'
          : 'bg-gradient-to-r from-red via-secondary to-secondary'
      }`}
    >
      <div className="relative">
        <img
          src="/champimages/quinn.png"
          alt="Champion"
          className="w-12 h-12 rounded"
        />
        <div className="absolute bottom-0 right-0 mb-[-4px] mr-[-4px] bg-black text-white rounded-full w-5 h-5 flex items-center justify-center border-2 border-gold text-xxs">
          16
        </div>
      </div>
      <div className="ml-2">
        <div className="flex items-center">
          <p className="font-beaufort text-xs mr-2">
            {isVictory ? 'VICTORY' : 'DEFEAT'}
          </p>
          <span
            className={
              isVictory
                ? 'text-green font-bold text-xs'
                : 'text-red font-bold text-xs'
            }
          >
            {isVictory ? '+20 LP' : '-20 LP'}
          </span>
        </div>
        <p className="text-xs mb-1">Ranked Solo/Duo</p>
        <div className="flex">
          <img src="/spell/4.png" alt="Spell 1" className="w-4 h-4" />
          <img src="/spell/11.png" alt="Spell 2" className="w-4 h-4" />
          <img src="/perk-images/Styles/7200_Domination.png" alt="Spell 3" className="w-4 h-4 ml-8" /> {/* Added new image with left margin */}
          <img src="/perk-images/Styles/7201_Precision.png" alt="Spell 4" className="w-4 h-4" /> {/* Added new image */}
        </div>
      </div>
      <span className="absolute top-0 right-0 m-1 text-xs text-gray-500 opacity-70">
        3 hours ago
      </span>
      <div className="absolute inset-0 flex items-center justify-center ml-20 flex-col">
        <span className="text-white text-xxs bg-black px-2 py-1 rounded">KDA: 12/2/11</span> {/* Hardcoded KDA text */}
        <span className="text-white font-bold text-xxs bg-purple-600 px-0.5 py-0.25 rounded">ACE</span> {/* Hardcoded KDA text */}
        <span className="text-white font-bold text-xxs bg-blue-950 px-0.5 py-0.25 rounded">PENTAKILL</span> {/* Hardcoded KDA text */}
      </div>

      <div className="absolute bottom-0 right-0 m-2 flex items-center">
        {' '}
        {/* Updated to flex and added items-center */}
        <img
          src="/item/1001.png"
          alt="Extra Item"
          className="w-4 h-4 mr-1"
        />{' '}
        {/* Added the extra item image */}
        <div className="grid grid-cols-3 gap-1">
          {items.map((item, index) => (
            <img
              key={index}
              src={item}
              alt={`Item ${index + 1}`}
              className="w-4 h-4"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MatchHistoryItem;
