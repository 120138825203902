import React from "react";
import CustomTooltip from "../CustomToolTip";

interface ChampionSpellsImagesProps {
  icons: string[]; // Array of image paths
  skilllabels: string[];
  tooltipContents?: string[]; // Add this line for tooltip content
  header: string[];
}

const ChampionSpellsImages: React.FC<ChampionSpellsImagesProps> = ({
  icons,
  skilllabels,
  tooltipContents = [],
  header = [],
}) => {
  return (
    <div className="flex flex-col gap-1">
      {icons.map((icon, index) => (
        <CustomTooltip
          position="top"
          key={index}
          header={header[index] || ""}
          content={tooltipContents[index] || ""}
          cost=""
        >
          <div className="relative w-6 h-6">
            <img
              src={`/spell/${icon}`}
              alt={`Skill Icon ${index + 1}`}
              className="w-full h-full rounded"
            />
            {/* Skill label (Q, W, E, R) */}
            <span className="absolute bottom-0 left-0 transform -translate-x-1/2 -translate-y-1/3 bg-greyborder2 text-black rounded-full px-0.5 text-xxs text-white flex items-center justify-center w-4 h-4">
              {skilllabels[index]}
            </span>
          </div>
        </CustomTooltip>
      ))}
    </div>
  );
};

export default ChampionSpellsImages;
