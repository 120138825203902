import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import DOMPurify from 'dompurify';
import { Tooltip } from '@material-tailwind/react';

interface CustomTooltipProps {
  content: string;
  header: string;
  children: React.ReactNode;
  position?: 'top' | 'right' | 'bottom' | 'left';
  cost?: string;
}
//Rename to zbbicomponent
const CustomTooltip: React.FC<CustomTooltipProps> = ({
  content,
  header,
  position = 'top', // Default to top
  children,
  cost,
}) => {
  const [parsedContent, setParsedContent] = useState<React.ReactNode>(null);

  useEffect(() => {
    const parser = new DOMParser();
    const sanitizedContent = DOMPurify.sanitize(content);
    const doc = parser.parseFromString(sanitizedContent, 'text/html');

    const transformNodeToComponent = (
      node: ChildNode,
      index: number
    ): React.ReactNode => {
      const key = `node-${index}`;
      switch (node.nodeName) {
        case 'LOL-UIKIT-TOOLTIPPED-KEYWORD':
          return (
            <span key={key} className="">
              {Array.from(node.childNodes).map((childNode, childIndex) =>
                transformNodeToComponent(childNode, childIndex)
              )}
            </span>
          );
        case 'FONT':
          const color = (node as Element).getAttribute('color') as string;
          return (
            <span key={key} style={{ color }}>
              {node.textContent}
            </span>
          );
        case 'BR':
          return <br key={key} />;
        case 'HR':
          return <hr key={key} className="my-2" />;
        case 'I':
          return (
            <i key={key} className="italic">
              {Array.from(node.childNodes).map((childNode, childIndex) =>
                transformNodeToComponent(childNode, childIndex)
              )}
            </i>
          );
        default:
          return node.textContent ? (
            <span key={key}>{node.textContent}</span>
          ) : null;
      }
    };
    setParsedContent(
      Array.from(doc.body.childNodes).map((node, index) =>
        transformNodeToComponent(node, index)
      )
    );
  }, [content]);

  const tooltipContent = (
    <>
      <div className="text-green rounded-t-md font-semibold text-s md:text-base">
        {header}
      </div>
      <div className="w-full h-0.5 bg-gray-700 my-1"></div>
      <div className="mt-2 text-xs md:text-sm">{parsedContent}</div>
      {cost && (
        <div className="mt-2 text-left font-bold text-xs">
          <span className="text-white">Cost:</span>{' '}
          <span className="text-gold">{cost}</span>
        </div>
      )}
    </>
  );

  return (
    <Tooltip
      content={tooltipContent}
      placement={position}
      className="border border-gray-700 bg-primary px-4 py-3 shadow-2xl md:w-[18rem] md:min-w-[9rem] md:max-w-[30rem] break-words"
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
