import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MatchHistory from './components/matchhistory/MatchHistory';
import SummonerDetails from './components/summonerdetails/SummonerDetails';

const Summoner: React.FC = () => {
  const { region, summonername, tagline } = useParams<{ region: string; summonername: string; tagline: string }>();
  const [summonerData, setSummonerData] = useState<any>(null);

  useEffect(() => {
    const fetchSummonerData = async () => {

      try{
        const response = await fetch('http://127.0.0.1:5001/test-cloud-functions-3d5eb/us-central1/getMatchesByGameNameAndTagLine', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ "regionalServer":  "euw1", "gameName": summonername, "tagLine": tagline }),
        });
        const data = await response.json();
        console.log("data: ", data)
        setSummonerData(data);  
      }catch(error){
        console.log("Error ", error)
      }
    };

    fetchSummonerData();
  }, [region, summonername, tagline]);

  return (
    <div className="container mx-auto">
      <SummonerDetails 
      tagName={tagline}
      summonerName={summonername}
      profileIcon={summonerData?.profile?.profileIcon}
      rank={summonerData?.profile?.rank?.[0]?.rank}
      /> 
      <MatchHistory  /> 
    </div>
  );
};

export default Summoner;
