import React, { FC, useState } from 'react';
import CustomTooltip from '../CustomToolTip';

interface BootsImgProps {
  itemId: number;
  tooltipContent?: string; // Add this line
  header: string;
  cost: string;
}

const BootsImg: FC<BootsImgProps> = ({
  itemId,
  tooltipContent,
  header,
  cost,
}) => {
  return (
    <CustomTooltip
      position="top"
      header={header}
      cost={cost}
      content={tooltipContent || ''}
    >
      <img src={`/item/${itemId}.png`} alt="" className="h-8 md:h-10 rounded" />
    </CustomTooltip>
  );
};

interface ImageLoaderProps {
  src: string;
  alt: string;
  className: string;
}

export const ImageLoader: FC<ImageLoaderProps> = ({ src, alt, className }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  className += imageLoaded ? ' opacity-100 ' : ' opacity-0';
  return (
    <>
      {!imageLoaded && (
        <div
          className={`animate-pulse bg-gray-200 ${className} rounded-lg`}
        ></div>
      )}
      <img
        src={src}
        alt={alt}
        className={className + `${imageLoaded ? ' ' : ' hidden'}`}
        onLoad={() => setImageLoaded(true)}
      />
    </>
  );
};

export default BootsImg;
