import React from 'react';

type ImageNavBarProps = {
  buttonLabels: string[];
  activeLabel: string;
  onTabChange: (label: string) => void;
};

const ImageNavBar: React.FC<ImageNavBarProps> = ({
  buttonLabels,
  onTabChange,
  activeLabel,
}) => {
  return (
    <div className="container w-full md:w-4/12 z-40 bottom-0 left-0 mt-0 sm:mt-0 shadow-lg rounded-mt rounded-tr-lg">
      <div className="flex items-center justify-between bg-green w-[60%] rounded">
        <ul className="flex m-0 p-0 w-full justify-between">
          {buttonLabels.map((label, index) => (
            <li key={index} className="flex-1 text-center">
              <a
                className={`inline-block py-1 md:py-2 px-2 md:px-4 text-sm md:text-base font-semibold  
                  ${
                    label === activeLabel
                      ? 'text-primary'
                      : 'text-primary hover:text-sky-500'
                  }
                `}
                onClick={(e) => {
                  e.preventDefault(); // Prevent default anchor behavior
                  onTabChange(label);
                }}
                href="#"
              >
                <span
                  className={`border-b-4 md:pb-1 ${
                    label === activeLabel
                      ? 'border-primary rounded'
                      : 'border-transparent'
                  }`}
                >
                  {label}
                </span>
              </a>
            </li>
          ))}
        </ul>
        <div className="hidden md:block">
          <svg
            width="400"
            height="40"
            viewBox="100 0 400 40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#57F287"
              transform="scale(-1,-1) translate(-400,-40)"
              d="M 100 0 Q 150 50, 500 100 V 0 H 100 Z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ImageNavBar;
