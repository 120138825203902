import React, { FC } from 'react';
import { Boots } from '../../../../firebase/championDAO';
import BootsImg from './BootsImg';

interface RecommendedBootsProps {
  recommendedBoots: Boots[];
  itemData: any;
}

const RecommendedBoots: FC<RecommendedBootsProps> = ({
  recommendedBoots,
  itemData,
}) => {
  if (recommendedBoots.length < 1) return null;
  return (
    <div className="flex flex-col h-full p-2 rounded-md bg-primaryShade gap-1 self-start w-full">
      <div className="grid grid-cols-4 gap-y-2 items-center text-white text-1xs">
        <span className="font-semibold flex items-center justify-self-start">
          <div className="w-0.5 h-4 md:h-6 bg-green mr-1 md:mr-2"></div>
          <span className="text-sm md:text-lg font-bold">Boots</span>
        </span>
        <div className="flex text-xxs flex-col items-center lg:text-xs  text-win-rate border-r border-primary pr-2 pl-1">
          <span>Win</span>
          <span>Rate</span>
        </div>
        <div className="flex text-xxs flex-col items-center lg:text-xs  text-pick-rate border-r border-primary pr-2 pl-1">
          <span>Pick</span>
          <span>Rate</span>
        </div>
        <div className="flex text-xxs flex-col items-center lg:text-xs border-r border-transparent text-matches">
          <span>Matches</span>
        </div>

        {recommendedBoots.map((boots, index) => (
          <React.Fragment key={index}>
            <div className="flex flex-row gap-2 ">
              <BootsImg
                itemId={boots.boots}
                tooltipContent={
                  itemData['item_' + boots.boots]?.description || ''
                }
                header={itemData['item_' + boots.boots]?.name || ''}
                cost={`${itemData['item_' + boots.boots]?.gold.total || ''} (${
                  itemData['item_' + boots.boots]?.gold.base || ''
                })`}
              />
            </div>
            <span className="text-sm-stats md:text-2xs text-win-rate text-center">
              {Math.round(boots.winRate * 100)}%
            </span>
            <span className="text-sm-stats md:text-2xs text-pick-rate text-center">
              {Math.round(boots.pickRate * 100)}%
            </span>
            <span className="text-sm-stats md:text-2xs text-center text-matches">
              {boots.matchesPlayed}
            </span>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default RecommendedBoots;
