import { Opponent } from '../../../../../firebase/championDAO';
import { useNavigate } from 'react-router-dom';

interface OpponentCardProps {
  opponent: Opponent;
}

const OpponentCard: React.FC<OpponentCardProps> = ({ opponent }) => {
  const navigate = useNavigate();

  function handleCardClick(opponent: Opponent) {
    navigate(`/champion/${opponent.opponentChampionName}`);
  }
  return (
    <button onClick={() => handleCardClick(opponent)}>
      <div className="flex-none text-center bg-primary pt-1 rounded-md w-32 md:w-24 border border-secondary2">
        <img
          src={`/championImages/${opponent.opponentChampionName}_0.jpg`}
          alt={opponent.opponentChampionName}
          className="h-10 mx-auto rounded-full shadow-md shadow-cyan-400/20 border-cyan-700 border-2"
        />
        <div className="text-xs font-bold">{opponent.opponentChampionName}</div>

        <p className="text-sm-stats text-win-rate">
          {Math.round(opponent.winRate * 100)}%
        </p>
        <p className="text-xxs">Matches</p>
        <p className="text-sm-stats">{Math.round(opponent.matchesPlayed)}</p>
      </div>
    </button>
  );
};

export default OpponentCard;
