import React, { FC, useRef, useEffect } from 'react';
import { Opponent } from '../../../../../firebase/championDAO';
import OpponentCard from './OpponentCard';

interface CarouselProps {
  opponents: Opponent[];
}

const OpponentsComponent: FC<CarouselProps> = ({ opponents }) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (carouselRef.current) {
        carouselRef.current.scrollLeft += e.deltaY;
        e.preventDefault();
      }
    };

    const carouselEl = carouselRef.current;
    if (carouselEl) {
      carouselEl.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (carouselEl) {
        carouselEl.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  return (
    <div
      className="flex flex-row space-x-9 overflow-x-auto w-full ml-1 mr-1 pb-2"
      ref={carouselRef}
    >
      {opponents.map((opponent, index) => {
        return <OpponentCard key={index} opponent={opponent} />;
      })}
    </div>
  );
};

export default OpponentsComponent;
