import React from "react";
import RankDetails from "./components/RankDetails";


interface SummonerDetailsProps {
  summonerName?: string
  profileIcon: number
  rank: string
  tagName: string | undefined

}

const SummonerDetails: React.FC<SummonerDetailsProps> = ({tagName, summonerName,
   profileIcon, rank}) => {
  return (
    <div className="flex flex-col items-start p-4 space-y-4 w-full">
      <div className="flex items-start relative">
          <img
            src={`/profileicon/${profileIcon}.png`}
            alt="Summoner Profile"
            className="w-14 h-14 rounded-xl mr-4 border-2 border-sky-500"
          />
          <div className="flex flex-col">
        <div className="text-xl font-bold">{summonerName}</div>
        <div className="text-small text-gray-400" > #{tagName}</div>
        <div className="text-xs text-gray-600">Leaderboard: {rank}</div>
    </div>
      </div>

      {/* Rank Details */}
      <div className="flex justify-between items-start w-full">
        <RankDetails />
      </div>

      {/* Seasons Ranking - Using horizontal scroll */}
      <div className="w-full mt-4">
        <div className="mb-2 text-xs font-semibold text-white opacity-80">
            Season Ranking History
        </div>
        <div className="overflow-x-auto">
            <div className="flex space-x-2">
                {["3", "4", "5", "6", "7", "8", "9", "10", "11", "12"].map((season) => (
                    <div
                        key={season}
                        className="text-xxs px-2 py-1 rounded shadow-md shadow-secondary transition-transform transform hover:scale-105 whitespace-nowrap bg-sky-500/50 text-white"
                    >
                        {`S${season}: Grand Master`}
                    </div>
                ))}
            </div>
        </div>
    </div>

      {/* Champions */}
      <div className="w-full space-y-2">
        {[
          {
            name: "Aatrox",
            matches: 150,
            winrate: 56,
            kda: "3.4",
          } /* Mockup data, add more as needed */,
        ].map((champion) => (
          <div key={champion.name} className="flex items-center space-x-2">
            <img
              src={`/champimages/${champion.name}.png`}
              alt={`${champion.name} Champion`}
              className="w-12 h-12"
            />
            <div>
              <div className="font-medium">{champion.name}</div>
              <div className="text-xs">{`${champion.matches} Matches`}</div>
              <div className="text-xs">{`${champion.winrate}% WR`}</div>
              <div className="text-xs">{`${champion.kda} KDA`}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SummonerDetails;
