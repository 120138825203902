import React from 'react';
import MatchHistoryItem from './MatchHistoryItem';
import MatchHistoryDropdown from './MatchHistoryDropdown';

const MatchHistory: React.FC = () => {
  return (
    <div className="p-2">
      <MatchHistoryDropdown/>
      <div className="flex flex-wrap -m-1">
        {Array.from({ length: 5 }).map((_, index) => (
          <div key={index} className="m-1 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
            <MatchHistoryItem matchIndex={index} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MatchHistory;
