import React, { FC } from 'react';
import CustomTooltip from '../CustomToolTip';
import { ImageLoader } from '../boots/BootsImg';

interface ItemImgProps {
  itemId: number;
  count?: number;
  tooltipContent?: string; // Add this line
  header: string;
  cost: string;
}

const ItemImg: FC<ItemImgProps> = ({
  itemId,
  count,
  tooltipContent,
  header,
  cost,
}) => {
  return (
    <CustomTooltip
      position="top"
      header={header}
      cost={cost}
      content={tooltipContent || ''}
    >
      <div className="relative">
        <img
          src={`/item/${itemId}.png`}
          alt=""
          className="h-8 md:h-10 rounded"
        />
        {count && count > 1 && (
          <span className="absolute bottom-0 left-0 bg-greyborder2 rounded-full px-1 text-xxxs md:text-xs text-white">
            x{count}
          </span>
        )}
      </div>
    </CustomTooltip>
  );
};

export default ItemImg;
