import React from 'react';

const MatchHistoryDropdown: React.FC = () => {

  return (
    <div className="flex justify-between items-center mb-2">
        <h2 className="text-lg font-bold">Match History</h2>
        <div className="flex space-x-2">
          {['All', 'All'].map((label, idx) => (
            <div key={idx}>
              <button 
                id={`dropdownDefaultButton-${idx}`} 
                data-dropdown-toggle={`dropdown-${idx}`} 
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-4 py-1.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {label}
                <svg className="w-2 h-2 ml-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                </svg>
              </button>
              <div id={`dropdown-${idx}`} className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-32 dark:bg-gray-700">
                <ul className="py-2 text-xs text-gray-700 dark:text-gray-200" aria-labelledby={`dropdownDefaultButton-${idx}`}>
                  <li>
                    <a href="#" className="block px-3 py-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                  </li>
                  <li>
                    <a href="#" className="block px-3 py-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
                  </li>
                  <li>
                    <a href="#" className="block px-3 py-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
                  </li>
                  <li>
                    <a href="#" className="block px-3 py-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</a>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
  );
};

export default MatchHistoryDropdown;
