export const adaptiveForceMod = {
  id: 5008,
  name: 'Adaptive Force',
  longDesc:
    "+9 <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_Adaptive'><font color='#48C4B7'>Adaptive Force</font></lol-uikit-tooltipped-keyword>",
  iconPath: '/perk-images/StatMods/StatModsAdaptiveForceIcon.png',
};

export const armorMod = {
  id: 5002,
  name: 'Armor',
  longDesc: '+6 Armor',
  recommendationDescriptor: '',
  iconPath: '/perk-images/StatMods/StatModsArmorIcon.png',
};

export const attackSpeedMod = {
  id: 5005,
  name: 'Attack Speed',
  longDesc: '+10% Attack Speed',
  iconPath: '/perk-images/StatMods/StatModsAttackSpeedIcon.png',
};

export const abilityHasteMod = {
  id: 5007,
  name: 'Ability Haste',
  tooltip: '+@f1@ Ability Haste',
  longDesc:
    "+8 <lol-uikit-tooltipped-keyword key='LinkTooltip_Description_CDR'>Ability Haste</lol-uikit-tooltipped-keyword> ",
  iconPath: '/perk-images/StatMods/StatModsCDRScalingIcon.png',
};

export const healthMod = {
  id: 5001,
  name: 'Health Scaling',
  longDesc: '+15-140 Health (based on level)',
  iconPath: '/perk-images/StatMods/StatModsHealthScalingIcon.png',
};

export const magicResistMod = {
  id: 5003,
  name: 'Magic Resist',
  shortDesc: '+8 Magic Resist',
  iconPath: '/perk-images/StatMods/StatModsMagicResIcon.png',
};
